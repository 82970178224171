import { ApplicationColors } from "@application/Configurations/color.configuration";
import NumberThousandSeparator from "@infrastructure/components/interface/textFormat/NumberThousandSeparator";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import type { IChartSeriesItem } from "@infrastructure/model/interfaces/chart/chart.series.item.interface";
import { useTheme } from "@mui/material";

interface Props {
	sqSize: number;
	strokeWidth: number;
	statistics: Array<IChartSeriesItem>;
}

const StatsCircle = (props: Props & dataCypressProp) => {
	const theme = useTheme();
	const { sqSize, strokeWidth, statistics, dataCypressID } = props;
	const radius = (sqSize - strokeWidth) / 2;
	const viewBox = `0 0 ${sqSize} ${sqSize}`;
	const dashArray = radius * Math.PI * 2;

	const total = statistics.map((item: IChartSeriesItem) => item.value).reduce((a: number, b: number) => a + b);

	return (
		<svg width={sqSize} height={sqSize} viewBox={viewBox}>
			{total > 0 ? (
				statistics.map((item: IChartSeriesItem, index: number) => {
					const dashOffset = dashArray - (dashArray * item.value) / total;
					const sumPrev =
						index === 0
							? 0
							: statistics
									.slice(0, index)
									.map((_item: IChartSeriesItem) => _item.value)
									.reduce((a: number, b: number) => a + b);

					const rotate = -90 + (sumPrev / total) * 360;

					return (
						<circle
							key={index}
							cx={sqSize / 2}
							cy={sqSize / 2}
							r={radius}
							strokeWidth={`${strokeWidth}px`}
							transform={`rotate(${isNaN(rotate) ? 0 : rotate} ${sqSize / 2} ${sqSize / 2})`}
							style={{
								strokeDasharray: dashArray,
								strokeDashoffset: isNaN(dashOffset) ? 0 : dashOffset,
								stroke: item.color,
								fill: "none",
							}}
						/>
					);
				})
			) : (
				<circle
					cx={sqSize / 2}
					cy={sqSize / 2}
					r={radius}
					strokeWidth={"10px"}
					style={{
						stroke: ApplicationColors.greyStone.main,
						fill: "none",
					}}
				/>
			)}
			<text
				x="50%"
				y="50%"
				dy=".3em"
				textAnchor="middle"
				data-cy={dataCypressID ? `${dataCypressID}-donut_chart_value` : undefined}
				style={{
					fontSize: "1.5rem",
					fontWeight: "bold",
					fill: theme.palette.text.primary,
				}}
			>
				<NumberThousandSeparator value={total} mustBePureRendering={true} />
			</text>
		</svg>
	);
};

export default StatsCircle;
