import {
	type DtoRole,
	queryFilters,
	queryStringPagination,
	queryStringSorts,
	Services,
	type TypeApiQueryPagination,
	type TypeApiResponsePagined,
	type TypeApiResponsePost,
} from "@key4-front-library/core";

/**
 * Get list of roles
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param filters Search word
 * @param sorts Sort fields and directions
 * @returns List of roles
 */
const getList = async (clientId: string, eventId: string, filters?: string, sorts: Array<string> = ["name"]): Promise<Array<DtoRole>> =>
	await Services.Operations.RoleService.getList(clientId, eventId, [...queryFilters(filters), ...queryStringSorts(sorts)]);

/**
 * Get list of roles
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param filters Search word
 * @param sorts Sort fields and directions
 * @param pagination pagination properties
 * @returns List of roles
 */
const getListPagined = async (
	clientId: string,
	eventId: string,
	filters?: string,
	sorts: Array<string> = ["name"],
	pagination?: TypeApiQueryPagination,
): Promise<TypeApiResponsePagined<Array<DtoRole>>> =>
	await Services.Operations.RoleService.getListPagined(clientId, eventId, [
		...queryFilters(filters),
		...queryStringSorts(sorts),
		...queryStringPagination(pagination),
	]);

/**
 * Create a new Role
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param role Role data
 * @typedef DtoRole Role Dto
 * @return TypeApiResponsePost
 * @typedef TypeApiResponsePost Post API response
 */
const create = async (clientId: string, eventId: string, role: DtoRole): Promise<TypeApiResponsePost> =>
	await Services.Operations.RoleService.post(clientId, eventId, role);

/**
 * Update a Role
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param role Role data
 * @typedef DtoRole Role Dto
 * @returns Success boolean
 */
const update = async (clientId: string, eventId: string, role: DtoRole): Promise<boolean> => await Services.Operations.RoleService.put(clientId, eventId, role);

/**
 * Delete a role
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param id ID of Role to delete
 * @returns Success boolean
 */
const deleteEntity = async (clientId: string, eventId: string, id: string): Promise<boolean> =>
	await Services.Operations.RoleService.deleteEntity(clientId, eventId, id);

/**
 * Role Controller
 */
const RoleController = {
	create,
	deleteEntity,
	getList,
	getListPagined,
	update,
};

export default RoleController;
