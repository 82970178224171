import { motion } from "framer-motion";
import React from "react";
import { useDrag, useDrop } from "react-dnd";

import Role from "@application/Components/role/Role";
import Card from "@infrastructure/components/interface/card/Card";
import type { IMenuItems } from "@infrastructure/model/interfaces/menuItems.interface";
import type { DataCypress, DtoDashboardRole } from "@key4-front-library/core";
import { EnumRoleScope } from "@key4-front-library/core/Enums";
import { Box } from "@mui/material";

import type { Identifier, XYCoord } from "dnd-core";
const ItemTypes = {
	CARD: "card",
};

const style = {
	cursor: "move",
};

interface DragItem {
	index: number;
	id: string;
	type: string;
}

type Props = DataCypress & {
	id: string;
	index: number;
	role: DtoDashboardRole;
	menuItems?: IMenuItems;
	moveCard: (dragIndex: number, hoverIndex: number) => void;
	setPinnedRoleClicked: (role: DtoDashboardRole) => void;
};

const RoleCardDargContainer = (props: Props) => {
	const { id, index, role, menuItems, moveCard, setPinnedRoleClicked, dataCypressID } = props;

	const ref = React.useRef<HTMLDivElement>(null);
	const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
		accept: ItemTypes.CARD,
		collect(monitor) {
			return {
				handlerId: monitor.getHandlerId(),
			};
		},
		hover(item: DragItem, monitor) {
			if (!ref.current) {
				return;
			}
			const dragIndex = item.index;
			const hoverIndex = index;

			// Don't replace items with themselves
			if (dragIndex === hoverIndex) {
				return;
			}

			// Determine rectangle on screen
			const hoverBoundingRect = ref.current.getBoundingClientRect();

			// Get vertical middle
			const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

			// Determine mouse position
			const clientOffset = monitor.getClientOffset();

			// Get pixels to the top
			const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

			// Only perform the move when the mouse has crossed half of the items height
			// When dragging downwards, only move when the cursor is below 50%
			// When dragging upwards, only move when the cursor is above 50%

			// Dragging downwards
			if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
				return;
			}

			// Dragging upwards
			if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
				return;
			}

			// Time to actually perform the action
			moveCard(dragIndex, hoverIndex);

			// Note: we're mutating the monitor item here!
			// Generally it's better to avoid mutations,
			// but it's good here for the sake of performance
			// to avoid expensive index searches.
			item.index = hoverIndex;
		},
	});

	const [{ isDragging }, drag] = useDrag({
		type: ItemTypes.CARD,
		item: () => {
			return { id, index };
		},
		collect: (monitor: any) => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const opacity = isDragging ? 0.5 : 1;
	drag(drop(ref));
	return (
		<Box ref={ref} style={{ ...style, opacity }} data-handler-id={handlerId}>
			<motion.div whileHover={{ scale: 1.05 }}>
				<Card
					children={
						<Role
							id={role.id}
							name={role.name}
							value={role.value}
							color={role.color}
							icon={role.icon}
							scope={EnumRoleScope.PARTICIPANT}
							type={role.type}
							dataCypressID={dataCypressID}
						/>
					}
					pinIcon={{ prefix: "fas" }}
					menuItems={menuItems}
					setDataPinnedElementClicked={setPinnedRoleClicked}
					dataCypressID={dataCypressID}
				/>
			</motion.div>
		</Box>
	);
};

export default RoleCardDargContainer;
