import RoleCard from "@application/Components/dashboard/roles/components/RoleCard";
import { type DataCypress, type DtoDashboardRole, type DtoRole, getDataCypressID } from "@key4-front-library/core";

type Props = DataCypress & {
	roles: Array<DtoDashboardRole>;
	isManage: boolean;
	setRoles: (roles: any) => void;
	setIsModaleOpen: (open: boolean) => void;
	setRoleToEdit: (role: DtoRole) => void;
	setPinnedRoleClicked: (role: DtoDashboardRole) => void;
	xs: number;
	sm: number;
	md: number;
	lg: number;
	xl: number;
};

const ListRoles = (props: Props) => {
	const { roles, isManage, setRoles, setIsModaleOpen, setRoleToEdit, setPinnedRoleClicked, xs, sm, md, lg, xl, dataCypressID } = props;

	return (
		<>
			{roles &&
				roles.map((_role: DtoDashboardRole, _index: number) => (
					<RoleCard
						key={_role.id}
						role={_role}
						index={_index}
						setRoles={setRoles}
						isManage={isManage}
						setIsModaleOpen={setIsModaleOpen}
						setRoleToEdit={setRoleToEdit}
						setPinnedRoleClicked={setPinnedRoleClicked}
						xs={xs}
						sm={sm}
						md={md}
						lg={lg}
						xl={xl}
						dataCypressID={getDataCypressID(dataCypressID, ["role", "card"])}
					/>
				))}
		</>
	);
};

export default ListRoles;
