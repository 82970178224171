import type { TFunction } from "i18next";

import type { IMenuItems } from "@infrastructure/model/interfaces/navigation/menuItems.interface";

interface GetMenuItemsTranslationsProps {
	menuItems: IMenuItems;
	t: TFunction;
}
const GetMenuItemsTranslations = (props: GetMenuItemsTranslationsProps): IMenuItems => {
	const { menuItems, t } = props;
	menuItems.map((menuItem) => {
		menuItem.name = t(`old.registration.privileges.tabs.${menuItem.reference}`) ?? menuItem.name;
		return menuItem;
	});
	return menuItems;
};

const SettingsFactory = {
	GetMenuItemsTranslations,
};

export default SettingsFactory;
