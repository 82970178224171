import type React from "react";
import { type MouseEventHandler, useRef, useState } from "react";

import { ApplicationColors } from "@application/Configurations/color.configuration";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";

import K4ButtonExport from "./K4ButtonExport";

interface Props {
	label: string;
	items: Array<K4SplitButtonExportItem>;
	isDisabled?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement>;
}

export interface K4SplitButtonExportItem {
	id: string;
	label: string;
	isDisabled?: boolean;
	onClick?: React.MouseEventHandler<HTMLLIElement>;
}

const K4SplitButtonExport = (props: Props) => {
	const { label, isDisabled = false, onClick, items } = props;

	const [open, setOpen] = useState(false);
	const anchorRef = useRef<HTMLDivElement>(null);

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: Event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return;
		}

		setOpen(false);
	};
	return (
		<>
			<ButtonGroup
				ref={anchorRef}
				variant="contained"
				aria-label="split button"
				sx={{
					"& .MuiButtonGroup-grouped:not(:last-of-type)": {
						borderColor: `${ApplicationColors.greyLight.main} !important`,
					},
				}}
			>
				<K4ButtonExport label={label} isDisabled={isDisabled} onClick={onClick} />
				<Button
					sx={{
						px: 0,
					}}
					color={"primary"}
					size={"small"}
					aria-controls={open ? "split-button-menu" : undefined}
					aria-expanded={open ? "true" : undefined}
					aria-label="select merge strategy"
					aria-haspopup="menu"
					onClick={handleToggle}
				>
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>
			<Popper
				sx={{
					zIndex: 1,
				}}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				placement="bottom-start"
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === "bottom" ? "center top" : "center bottom",
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu" autoFocusItem>
									{items.map((item, index) => (
										<MenuItem key={index} disabled={item.isDisabled ?? false} onClick={item.onClick}>
											{item.label}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
};

export default K4SplitButtonExport;
