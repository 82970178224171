import { t } from "i18next";
import { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import RolesSelect from "@infrastructure/components/forms/Select/RolesSelect";
import Loading from "@infrastructure/components/interface/loading/Loading";
import { ButtonCancel, ButtonSave } from "@infrastructure/components/interface/uikit/K4Button";
import { getSimilarityBetweenArrayOfObjectsAndArrayOfStrings } from "@infrastructure/services/helpers/arrayFunctions";
import FormFieldRule from "@infrastructure/services/i18n/i18nFormRulesParser";
import FormControlSelect from "@key4-front-library/core/Bo/Components/FormControl/FormControlSelect";
import type { DtoCostCenterGet, DtoPrivilegeWrite, DtoRole } from "@key4-front-library/core/Dto";
import { DialogActions, Stack, TextField } from "@mui/material";

interface Props {
	isNew: boolean;
	id?: string;
	privilege?: DtoPrivilegeWrite;
	handleSavePrivilege: (isNew: boolean, privilege: DtoPrivilegeWrite, id?: string) => void;
	handleModaleClose: () => void;
	costCenters: Array<DtoCostCenterGet>;
	roles: Array<DtoRole>;
}

const PrivilegeEditionForm = (props: Props) => {
	const { isNew, id, privilege, handleSavePrivilege, handleModaleClose, costCenters, roles } = props;

	const defaultAllocationRolesValues = useRef<Array<DtoRole>>([]);
	const defaultPotentialRolesValues = useRef<Array<DtoRole>>([]);

	const translations = {
		buttons: {
			save: t("old.form.buttons.save"),
			cancel: t("old.form.buttons.cancel"),
		},
		privileges: {
			name: t("old.registration.privileges.privilege.form.name"),
			allocationRoles: t("old.registration.privileges.privilege.form.allocationRoles"),
			potentialRoles: t("old.registration.privileges.privilege.form.potentialRoles"),
			costCenter: t("old.registration.privileges.privilege.form.costCenter"),
		},
	};

	const Schema: any = yup.object({
		name: yup.string().required(FormFieldRule.Required(translations.privileges.name)),
	});

	const {
		register,
		handleSubmit,
		control,
		setValue,
		formState: { isSubmitting, isDirty, isValid, errors },
	} = useForm<any>({
		resolver: yupResolver(Schema),
		defaultValues: privilege,
		mode: "onChange",
	});

	const handleAllocationRoleChange = (_e: React.SyntheticEvent, selectedRoles: ReadonlyArray<DtoRole>) => {
		// TODO create a generic component which accept or not useForm
		// it will avoid to set "isDirty" native param from useForm to true manually
		setValue(
			"currentRoleIds",
			selectedRoles.map((role: DtoRole) => role.id),
			{ shouldDirty: true },
		);
	};

	const handlePotentialRoleChange = (_e: React.SyntheticEvent, selectedRoles: ReadonlyArray<DtoRole>) => {
		// TODO create a generic component which accept or not useForm
		// it will avoid to set "isDirty" native param from useForm to true manually
		setValue(
			"potentialRoleIds",
			selectedRoles.map((role: DtoRole) => role.id),
			{ shouldDirty: true },
		);
	};

	const handleSubmitForm = (data: DtoPrivilegeWrite) => {
		handleSavePrivilege(isNew, data, id);
		handleModaleClose();
	};

	if (roles && privilege?.currentRoleIds) {
		defaultAllocationRolesValues.current = getSimilarityBetweenArrayOfObjectsAndArrayOfStrings(roles, privilege.currentRoleIds);
	}
	if (roles && privilege?.potentialRoleIds) {
		defaultPotentialRolesValues.current = getSimilarityBetweenArrayOfObjectsAndArrayOfStrings(roles, privilege.potentialRoleIds);
	}

	if (!roles) {
		return <Loading />;
	}

	return (
		<Stack spacing={3} mt={1}>
			<TextField
				label={translations.privileges.name}
				variant="outlined"
				{...register("name")}
				fullWidth
				error={!!errors.name}
				helperText={<>{errors.name?.message}</>}
				required
			/>
			<RolesSelect
				label={translations.privileges.allocationRoles}
				roles={roles}
				defaultRolesValues={defaultAllocationRolesValues.current}
				handleRoleChange={handleAllocationRoleChange}
			/>
			<RolesSelect
				label={translations.privileges.potentialRoles}
				roles={roles}
				defaultRolesValues={defaultPotentialRolesValues.current}
				handleRoleChange={handlePotentialRoleChange}
			/>
			<Controller
				name={"costCenterId"}
				control={control}
				render={({ field: { onChange, value } }) => (
					<FormControlSelect
						label={translations.privileges.costCenter}
						onChange={onChange}
						value={value}
						items={costCenters.map((costCenter) => {
							return {
								key: costCenter.id,
								label: costCenter.name,
							};
						})}
					/>
				)}
			/>
			<DialogActions>
				<ButtonCancel label={translations.buttons.cancel} onClick={handleModaleClose} sx={{ mr: 1 }} />
				<ButtonSave label={translations.buttons.save} disabled={!isDirty || !isValid || isSubmitting} onClick={handleSubmit(handleSubmitForm)} />
			</DialogActions>
		</Stack>
	);
};

export default PrivilegeEditionForm;
