import type { DtoRole } from "@key4-front-library/core";
import ControllersBo from "@key4-front-library/core/Bo/Controllers";
import { useQuery } from "@tanstack/react-query";

const useEntity = () => {
	const { readList: readListRole } = ControllersBo.RoleController.useEntity();

	const readList = (onlyTypeManualRoles = false) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: ["role", "list", onlyTypeManualRoles],
			queryFn: async (): Promise<Array<DtoRole>> => (await readListRole(undefined, onlyTypeManualRoles)) ?? [],
		});
		return { data, isLoading, refetch };
	};

	return { readList };
};

const RoleHook = {
	useEntity,
};

export default RoleHook;
