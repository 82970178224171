import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";

import CostCenterEditionForm from "@application/Components/settings/cost-centers/CostCenterEditionForm";
import CostCenterList from "@application/Components/settings/cost-centers/CostCenterList";
import CostCenterController from "@application/Controllers/CostCenterController";
import AppBox from "@infrastructure/components/interface/box/AppBox";
import DefaultModal from "@infrastructure/components/interface/modals/DefaultModal";
import SimpleTextSearchFilter from "@infrastructure/components/interface/search/SimpleTextSearchFilter";
import Title from "@infrastructure/components/interface/titles/Title";
import { EnumApiErrorStatus, PAGE, PAGINATION_PAGE_SIZE, useContextModule, useSnackBarHook } from "@key4-front-library/core";
import type { DtoCostCenterGet } from "@key4-front-library/core/Dto";
import { Box, Grid, Pagination, PaginationItem, Stack } from "@mui/material";

const PageSettingsCostCenters = () => {
	const { client, event } = useContextModule();
	const { sendSuccess, sendError } = useSnackBarHook();

	const pageSize = PAGINATION_PAGE_SIZE;
	const [page, setPage] = useState(PAGE + 1);
	const [totalRowCount, setTotalRowCount] = useState(0);

	const [search, setSearch] = useState<string>("");
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [isNewCostCenter, setIsNewCostCenter] = useState(false);
	const [currentCostCenter, setCurrentCostCenter] = useState<DtoCostCenterGet | undefined>();
	const [costCenters, setCostCenters] = useState<Array<DtoCostCenterGet>>([]);

	const pageResources = {
		title: t("old.registration.settings.costCenters.index.title"),
		editionModal: {
			title: t(`old.registration.settings.costCenters.edition.title.${isNewCostCenter ? "creation" : "modification"}`),
			returnMessages: {
				success_creation: t("old.registration.settings.costCenters.edition.returnMessages.success_creation"),
				success_modification: t("old.registration.settings.costCenters.edition.returnMessages.success_modification"),
				success_deletion: t("old.registration.settings.costCenters.edition.returnMessages.success_deletion"),
				error_generic: t("old.common.errors.generic"),
				error_costCenter_already_exist: t("old.registration.settings.costCenters.edition.returnMessages.error_costCenter_already_exist"),
			},
		},
		buttons: {
			add: t("old.form.buttons.add"),
		},
	};

	const handleOpenModalForm = (isNew: boolean, costCenter?: DtoCostCenterGet) => {
		setIsModalOpen(true);
		setIsNewCostCenter(isNew);
		setCurrentCostCenter(undefined);
		if (!isNew && costCenter) {
			setCurrentCostCenter(costCenter);
		}
	};

	const handleModaleClose = () => {
		setIsModalOpen(false);
	};

	const handleSaveCostCenter = (isNew: boolean, costCenter: DtoCostCenterGet) => {
		if (isNew) {
			CostCenterController.create(client.id, event.id, costCenter)
				.then(() => {
					sendSuccess(pageResources.editionModal.returnMessages.success_creation);
					getCostCenters();
					setIsModalOpen(false);
				})
				.catch((error) => {
					displayErrorSnackbar(error.status);
				});
		} else {
			CostCenterController.update(client.id, event.id, costCenter)
				.then(() => {
					sendSuccess(pageResources.editionModal.returnMessages.success_modification);
					getCostCenters();
					setIsModalOpen(false);
				})
				.catch((error) => {
					displayErrorSnackbar(error.status);
				});
		}
	};

	const displayErrorSnackbar = (errorStatus: number) => {
		if (errorStatus === EnumApiErrorStatus.STATUS_422) {
			sendError(pageResources.editionModal.returnMessages.error_costCenter_already_exist);
		} else {
			sendError(pageResources.editionModal.returnMessages.error_generic);
		}
	};

	const handleDeleteCostCenter = (id: string) => {
		CostCenterController.deleteEntity(client.id, event.id, id)
			.then(() => {
				sendSuccess(pageResources.editionModal.returnMessages.success_deletion);
				getCostCenters();
			})
			.catch(() => {
				sendError(pageResources.editionModal.returnMessages.error_generic);
			});
	};

	const handlePageChange = (_: React.ChangeEvent<unknown>, page: number) => {
		setPage(page);
	};

	const getCostCenters = useCallback(async () => {
		const costCentersData = await CostCenterController.getList(client.id, event.id, search, ["name"], {
			page: page - 1,
			pageSize,
		});

		const { data, pagination } = costCentersData;
		setTotalRowCount(pagination.totalCount);
		if (pagination.totalCount / pagination.pageSize < pagination.page) {
			setPage(1);
		}
		setCostCenters(data);
	}, [event, page, search]);

	useEffect(() => {
		getCostCenters();
	}, [event, getCostCenters]);

	return (
		<Grid container sx={{ marginTop: "-130px" }}>
			<Grid item xs={12}>
				<Title
					title=""
					buttons={[
						{
							label: pageResources.buttons.add,
							startIcon: "fa-plus",
							handleClick: () => {
								handleOpenModalForm(true);
							},
						},
					]}
				/>
			</Grid>
			<Grid item xs={12}>
				<AppBox data-testid="data">
					<Stack spacing={1}>
						<SimpleTextSearchFilter
							handleChange={(value: string) => {
								setSearch(value ?? "");
							}}
						/>

						<CostCenterList costCenters={costCenters} handleOpenModalForm={handleOpenModalForm} handleDelete={handleDeleteCostCenter} />

						<Box display={"flex"} justifyContent={"flex-end"} sx={{ py: 1 }}>
							<Pagination
								data-testid="pagination"
								page={page}
								count={Math.ceil(totalRowCount / pageSize)}
								size="small"
								onChange={handlePageChange}
								renderItem={(item) => <PaginationItem {...item} />}
							/>
						</Box>
					</Stack>
				</AppBox>
			</Grid>

			<DefaultModal open={isModalOpen} title={pageResources.editionModal.title}>
				<CostCenterEditionForm
					isNew={isNewCostCenter}
					nameAndId={currentCostCenter}
					handleSaveName={handleSaveCostCenter}
					handleModaleClose={handleModaleClose}
				/>
			</DefaultModal>
		</Grid>
	);
};

export default PageSettingsCostCenters;
