import {
	type DtoProfile,
	queryFilters,
	queryStringPagination,
	queryStringSorts,
	Services,
	type TypeApiQueryPagination,
	type TypeApiResponsePagined,
	type TypeApiResponsePost,
} from "@key4-front-library/core";

/**
 * Get pagined list of Profiles
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param filters Search word
 * @param sorts Sort fields and directions
 * @returns Promise of pagined list of DtoProfile
 * @typedef DtoProfile Profile Dto
 */
const getList = async (clientId: string, eventId: string, filters?: string, sorts: Array<string> = ["name"]): Promise<Array<DtoProfile>> =>
	await Services.Events.ProfileService.getList(clientId, eventId, [...(filters ? queryFilters(filters) : []), ...queryStringSorts(sorts)]);

/**
 * Get pagined list of Profiles
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param search Search word
 * @param sorts Sort fields and directions
 * @param pagination pagination properties
 * @returns Promise of pagined list of DtoProfile
 * @typedef DtoProfile Profile Dto
 */
const getListPagined = async (
	clientId: string,
	eventId: string,
	filters?: string,
	sorts: Array<string> = ["name"],
	pagination?: TypeApiQueryPagination,
): Promise<TypeApiResponsePagined<Array<DtoProfile>>> =>
	await Services.Events.ProfileService.getListPagined(clientId, eventId, [
		...(filters ? queryFilters(filters) : []),
		...queryStringPagination(pagination),
		...queryStringSorts(sorts),
	]);

/**
 * Create a new Profile
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param profile Profile data
 * @typedef DtoProfile Dto of Profile
 * @return TypeApiResponsePost
 * @typedef TypeApiResponsePost Post API response
 */
const create = async (clientId: string, eventId: string, profile: DtoProfile): Promise<TypeApiResponsePost> =>
	await Services.Events.ProfileService.post(clientId, eventId, profile);

/**
 * Update a Role
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param profile Profile data
 * @typedef DtoProfile Dto of Profile
 * @returns Success boolean
 */
const update = async (clientId: string, eventId: string, role: DtoProfile): Promise<boolean> =>
	await Services.Events.ProfileService.put(clientId, eventId, role);

/**
 * Delete a Profile
 * @param clientId Current client ID
 * @param eventId Current operation ID
 * @param id ID of Profile to delete
 * @returns  Success boolean
 */
const deleteEntity = async (clientId: string, eventId: string, id: string): Promise<boolean> =>
	await Services.Events.ProfileService.deleteEntity(clientId, eventId, id);

/**
 * Profile Controller
 */
const ProfileController = {
	getList,
	getListPagined,
	create,
	update,
	deleteEntity,
};

export default ProfileController;
