import { t } from "i18next";
import type React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import type { IconName } from "@fortawesome/fontawesome-svg-core";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import type { IMenuItems } from "@infrastructure/model/interfaces/navigation/menuItems.interface";
import { Box, Tab, Tabs } from "@mui/material";

interface Props {
	menuItems: IMenuItems;
	baseUrl: string;
	defaultTabsValue: string;
	routeParentLastPartPath: string;
}

const NavigationTabsWithIcons = (props: Props & dataCypressProp) => {
	const { menuItems, baseUrl, defaultTabsValue, routeParentLastPartPath, dataCypressID } = props;

	const location = useLocation();
	const navigate = useNavigate();

	const [tabValue, setTabValue] = useState<string>(defaultTabsValue);

	useEffect(() => {
		const handleUpdateTabValue = (value: string) => {
			const entries: Array<string> = menuItems.map((item) => item.reference);
			if (entries.includes(value)) {
				setTabValue(value);
			}
		};

		const routeComponents = location.pathname.split("/");
		const routeLastPartPath = routeComponents[routeComponents.length - 1];
		if (routeLastPartPath === "" || routeLastPartPath === routeParentLastPartPath) {
			navigate(`${baseUrl}/${defaultTabsValue}`, { replace: true });
			handleUpdateTabValue(defaultTabsValue);
		} else {
			handleUpdateTabValue(routeLastPartPath);
		}
	}, [baseUrl, defaultTabsValue, location, menuItems, navigate, routeParentLastPartPath, setTabValue]);

	const handleChange = (_: React.SyntheticEvent, value: string) => {
		if (value !== tabValue) {
			setTabValue(value);
			navigate(`${baseUrl}/${value}`);
		}
	};

	return (
		<Box sx={{ borderBottom: 1, borderColor: "divider", mb: 5 }}>
			{tabValue && (
				<Tabs value={tabValue} onChange={handleChange} centered={false}>
					{menuItems.map((item) => {
						return (
							<Tab
								key={item.reference}
								value={item.reference}
								label={<Box component="span">{item.translationKey ? t(item.translationKey) : item.name?.toLocaleUpperCase()}</Box>}
								icon={
									<Box mr={1}>
										<Icon iconName={item.icon as IconName} />
									</Box>
								}
								iconPosition="start"
								data-cy={dataCypressID ? `${dataCypressID}_${item.reference}` : undefined}
							/>
						);
					})}
				</Tabs>
			)}
		</Box>
	);
};

export default NavigationTabsWithIcons;
