import { t } from "i18next";
import { cloneDeep } from "lodash";
import { Fragment, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import ControllersApp from "@application/Controllers";
import HelpersApp from "@application/Helpers";
import HooksApp from "@application/Hooks";
import { ButtonCancel, ButtonSave, FormTabs, useSnackBarHook } from "@key4-front-library/core";
import { DialogTitle } from "@key4-front-library/core/Bo/Components/DialogActions";
import { DialogOld } from "@key4-front-library/core/Bo/Components/DialogOld";
import type { TypeUseFormListForms } from "@key4-front-library/core/Types";
import { Skeleton, Stack } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

export interface PropsContainerParticipantModal {
	participantId?: string;
	isOpen: boolean;
	changeIsOpen: (newIsOpen: boolean) => void;
	callbackSubmit?: () => void;
	listExtraData?: Array<any>; // Not working with primaryTag and secondariesTags
	activeTabKey?: string;
}

const ContainerModalParticipant = (props: PropsContainerParticipantModal) => {
	const { participantId, isOpen, callbackSubmit, changeIsOpen, activeTabKey } = props;

	const { sendSuccess, sendError } = useSnackBarHook();

	const { readList: readListManualsRoles } = HooksApp.RoleHook.useEntity();
	const { data: manualsRoles } = readListManualsRoles(true);

	const { readWholeForm } = HooksApp.ParticipantHook.useEntity();

	const { data, isFetching, refetch } = readWholeForm(participantId, manualsRoles);
	const { update, create } = ControllersApp.ParticipantController.useEntity();

	const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

	const formMethods = useForm<TypeUseFormListForms>({
		mode: "onSubmit",
		resolver: HelpersApp.ParticipantHelper.getResolver(data ? cloneDeep(data.useFormData) : undefined, participantId !== undefined),
	});

	const { handleSubmit: onSubmit, reset } = formMethods;
	useEffect(() => {
		if (!isFetching && data) {
			reset(data.useFormData);
		}
	}, [isFetching]);

	useEffect(() => {
		if (isOpen) {
			refetch();
		}
	}, [isOpen]);

	const handleSubmit = async (useFormData: TypeUseFormListForms) => {
		if (!participantId) {
			if (await create(useFormData)) {
				sendSuccess(t("old.registration.participants.modal.snackbar.created"));
				changeIsOpen(false);
				callbackSubmit?.();
			} else {
				sendError(t("old.registration.participants.modal.snackbar.error"));
			}
		} else if (await update(participantId, useFormData)) {
			sendSuccess(t("old.registration.participants.modal.snackbar.updated"));
			changeIsOpen(false);
			callbackSubmit?.();
		} else {
			sendError(t("old.registration.participants.modal.snackbar.error"));
		}
		setIsLoadingSubmit(false);
	};

	const handleInvalid = (_error: any) => {
		setIsLoadingSubmit(false);
		sendError(t("old.registration.participants.modal.snackbar.invalid"));
	};

	return (
		<DialogOld isOpen={isOpen}>
			<Fragment>
				<DialogTitle
					title={t(`old.registration.participants.edition.title.${participantId ? "modification" : "creation"}`)}
					onCloseClick={() => {
						changeIsOpen(false);
					}}
				/>
				<DialogContent>
					{data && !isFetching && (
						<FormProvider {...formMethods}>
							<FormTabs activeTabKey={activeTabKey} {...data.componentData} />
						</FormProvider>
					)}
					{isFetching && (
						<Stack spacing={2}>
							<Skeleton sx={{ height: 40 }} animation="wave" variant="rectangular" />
							<Skeleton sx={{ height: 450 }} animation="wave" variant="rectangular" />
						</Stack>
					)}
				</DialogContent>
				<DialogActions>
					<Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
						<ButtonCancel
							onClick={() => {
								changeIsOpen(false);
							}}
						/>
						<ButtonSave
							isLoading={isLoadingSubmit}
							disabled={!isOpen}
							onClick={(e) => {
								setIsLoadingSubmit(true);
								onSubmit(handleSubmit, handleInvalid)(e);
							}}
						/>
					</Stack>
				</DialogActions>
			</Fragment>
		</DialogOld>
	);
};

export default ContainerModalParticipant;
