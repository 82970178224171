import RoleEditionForm from "@application/Components/settings/roles/RoleEditionForm";
import DefaultModal from "@infrastructure/components/interface/modals/DefaultModal";
import { type DataCypress, type DtoDashboardRole, type DtoRole, getDataCypressID } from "@key4-front-library/core";

type Props = DataCypress & {
	open: boolean;
	role: DtoRole | undefined;
	title: string;
	onSave: (_role: DtoDashboardRole) => void;
	onClose: () => void;
};

const DashboardRoleEditModal = (props: Props) => {
	const { open, title, role, onSave, onClose, dataCypressID } = props;

	return (
		<DefaultModal open={open} title={title}>
			<RoleEditionForm
				isNew={false}
				role={role}
				handleSaveRole={(_isNew: boolean, _role: DtoRole) => {
					onSave({ ..._role, value: 0 });
				}}
				handleModaleClose={onClose}
				dataCypressID={getDataCypressID(dataCypressID, ["form"])}
			/>
		</DefaultModal>
	);
};

export default DashboardRoleEditModal;
