import { t } from "i18next";
import { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";

import ParticipantProfileCard from "@application/Components/participants/ParticipantProfileCard";
import ParticipantRolesCard from "@application/Components/participants/ParticipantRolesCard";
import ParticipantController from "@application/Controllers/ParticipantController";
import HooksApp from "@application/Hooks";
import {
	type DtoProfile,
	useContextModule,
	useSnackBarHook,
	getPersonTitle,
	TabsLayoutWithSidePanel,
	Typography,
	useTabsRoute,
	useCustomFieldContext,
	type TabProps,
	type EnumParticipantStatusInvitation,
	Stack,
	ErrorTemplate,
	getErrorTemplate,
	getButton,
} from "@key4-front-library/core";
import { Box, useTheme } from "@mui/material";
import { EnumParticipantFormKey } from "@application/Enums/ParticipantEnum";
import { customFormsToTabs } from "@pages";
import ContainerModalParticipant from "@application/Containers/ContainerModalParticipant";

export const ParticipantDetails = (): React.ReactNode => {
	const { sendSuccess, sendError } = useSnackBarHook();

	const { participantId: currentParticipantId } = useParams();
	const { client, event } = useContextModule();
	const theme = useTheme();

	const { get } = HooksApp.ParticipantHook.useEntity();
	const participantQuery = get(currentParticipantId);
	const { customForms } = useCustomFieldContext();
	const [tabs, setTabs] = useState<Array<TabProps>>();

	useEffect(() => {
		setTabs(customFormsToTabs(customForms?.filter((form) => form.key !== EnumParticipantFormKey.PROFILE_ROLES) ?? []));
	}, [customForms]);

	const [isOpenParticipantModal, setIsOpenParticipantModal] = useState(false);

	const handleChangeProfile = async (participantId: string, profile: DtoProfile | null) => {
		await ParticipantController.assignProfile(client.id, event.id, participantId, profile?.id ?? null)
			.then((response) => {
				if (response) {
					sendSuccess(t("old.registration.participantDetails.profile.updated.success"));
				} else {
					sendError(t("old.registration.participantDetails.profile.updated.error"));
				}
			})
			.catch((responseError) => {
				sendError(responseError);
			})
			.finally(() => participantQuery.refetch());
	};

	const handleChangeStatus = async (participantId: string, status: EnumParticipantStatusInvitation) => {
		await ParticipantController.assignInvitationStatus(client.id, event.id, participantId, { invitationStatus: status })
			.then((response) => {
				if (response) {
					sendSuccess(t("old.registration.participantDetails.invitationStatus.updated.success"));
				} else {
					sendError(t("old.registration.participantDetails.invitationStatus.updated.error"));
				}
			})
			.catch((responseError) => {
				sendError(responseError);
			})
			.finally(() => participantQuery.refetch());
	};

	const handleModalParticipantCallbackAction = async () => participantQuery.refetch();

	const { active } = useTabsRoute(tabs?.map((tab) => tab.to) ?? []);

	if (!tabs || tabs?.length === 0) {
		return undefined;
	}

	const tabActive = active !== -1 ? tabs[active] : tabs[0];

	if (active === -1) {
		return <ErrorTemplate to={`/${client.key}/${event.key}`} {...getErrorTemplate({ type: "404", options: { mode: theme.palette.mode } })} />;
	}
	if (participantQuery.data && currentParticipantId) {
		return (
			<Stack pt={5}>
				<ContainerModalParticipant
					participantId={currentParticipantId}
					isOpen={isOpenParticipantModal}
					changeIsOpen={setIsOpenParticipantModal}
					callbackSubmit={handleModalParticipantCallbackAction}
				/>
				<TabsLayoutWithSidePanel
					title={{
						children: <Typography variant="h4">{getPersonTitle(participantQuery.data)}</Typography>,
						buttonGroup: {
							buttons: [
								{
									onClick: () => setIsOpenParticipantModal(true),
									...getButton({ type: "update" }),
									children: t("old.form.buttons.edit"),
								},
							],
						},
					}}
					active={active}
					tabs={tabs}
					sidePanel={
						<Stack spacing={4}>
							<ParticipantProfileCard participant={participantQuery.data} onChangeProfile={handleChangeProfile} onChangeInvitationStatus={handleChangeStatus} />
							{participantQuery.data.roles?.length && (
								<Box>
									<ParticipantRolesCard roles={participantQuery.data.roles} />
								</Box>
							)}
						</Stack>
					}
				>
					<Outlet
						context={{
							key: tabActive.id,
							form: customForms?.filter((customForm) => customForm.key === tabActive.to)[0],
							formOrder: active,
							participant: participantQuery.data,
						}}
					/>
				</TabsLayoutWithSidePanel>
			</Stack>
		);
	}
};
