import PrivilegesMatrixFactory from "@application/factory/privileges/PrivilegesMatrixFactory";
import type { DtoBenefitValue, DtoPrivilegeCost } from "@key4-front-library/core";
import type { GridAlignment } from "@mui/x-data-grid-pro";

interface PopulateBenefitsMatrixProps {
	benefitsCosts: DtoPrivilegeCost;
	benefitsValues: Array<DtoBenefitValue>;
	translations: any;
	firstColumnMinWidth: { minWidth: number };
	defaultStyleColumnsParams: any;
}

const PopulateBenefitsMatrix = (props: PopulateBenefitsMatrixProps) => {
	const { benefitsCosts, benefitsValues, translations, firstColumnMinWidth, defaultStyleColumnsParams } = props;
	const formatRows = () => {
		const rows = [
			{
				id: 1,
				benefitRowLabel: translations.benefitsMatrix.potentialBenefits,
				...benefitsCosts.benefitsCosts.Potential,
			},
			{
				id: 2,
				benefitRowLabel: translations.benefitsMatrix.currentBenefits,
				...benefitsCosts.benefitsCosts.Current,
			},
		];
		return rows;
	};
	const formatColumns = () => {
		const columns = [
			{
				field: "benefitRowLabel",
				headerName: "",
				type: "string",
				cellClassName: "column-background-dark",
				...firstColumnMinWidth,
				headerAlign: "center" as GridAlignment,
			},
		];
		const columnsFromApi = [];
		// can't have type directly to know if it's a cell with a money type. So search type in benefitsCosts array
		for (const i in benefitsCosts.benefitsCosts.Current) {
			const benefitWithCorrespondingName = benefitsValues.filter((benefit) => benefit.name === i);
			const needsValueFormatter = PrivilegesMatrixFactory.isMoneyType(benefitWithCorrespondingName[0]?.valueType);
			columnsFromApi.push({
				field: i,
				description: i,
				type: "number",
				minWidth: 150,
				...(needsValueFormatter && {
					valueFormatter: ({ value }: any) => {
						return PrivilegesMatrixFactory.currencyFormatter.format(value);
					},
				}),
				...defaultStyleColumnsParams,
			});
		}
		return [...columns, ...columnsFromApi];
	};

	const rows = formatRows();
	const columns = formatColumns();
	return { rows, columns };
};

const PrivilegeDetailsFactory = {
	PopulateBenefitsMatrix,
};

export default PrivilegeDetailsFactory;
