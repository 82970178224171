import { t } from "i18next";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { invitationStatus } from "@application/Configurations/status.configuration";
import HooksApp from "@application/Hooks";
import FieldLabel from "@infrastructure/components/interface/titles/FieldLabel";
import FormControlSelect, { type TypeSelectItem } from "@key4-front-library/core/Bo/Components/FormControl/FormControlSelect";
import type { DtoParticipantGet, DtoProfile } from "@key4-front-library/core/Dto";
import type { EnumParticipantStatusInvitation } from "@key4-front-library/core/Enums";
import { Skeleton, Stack, Typography } from "@mui/material";

import SelectStatus from "./details/selectStatus/SelectStatus";
import { PaperTitle } from "@key4-front-library/core";

interface TypeProfileInputs {
	profileId: string | null;
}

interface PropsParticipantProfileCard {
	participant: DtoParticipantGet;
	onChangeProfile: (participantId: string, profile: DtoProfile | null) => void;
	onChangeInvitationStatus: (participantId: string, status: EnumParticipantStatusInvitation) => void;
}

const ParticipantProfileCard = (props: PropsParticipantProfileCard) => {
	const { participant, onChangeProfile, onChangeInvitationStatus } = props;

	const { readList } = HooksApp.ProfileHook.useEntity();
	const { data: profiles, isLoading } = readList();

	const { control, setValue } = useForm<TypeProfileInputs>({
		defaultValues: {
			profileId: participant.profile?.id ?? null,
		},
	});

	useEffect(() => {
		setValue("profileId", participant.profile?.id ?? null);
	}, [participant]);

	const handleChangeProfile = (profileId: string | null): void => {
		setValue("profileId", profileId);
		onChangeProfile(participant.id, profiles?.find((profile) => profileId === profile.id) ?? null);
	};
	const handleChangeStatus = (status: EnumParticipantStatusInvitation): void => {
		onChangeInvitationStatus(participant.id, status);
	};

	const translations = {
		profile: t("old.registration.settings.profiles.index.profile"),
		label: {
			highestRankPrivilege: t("old.registration.participantDetails.label:highestRankPrivilege"),
		},
	};

	if (isLoading) {
		return <Skeleton animation="wave" width={"xd"} height={40} />;
	}

	if (participant && profiles) {
		const items: Array<TypeSelectItem> = profiles.map((profile) => {
			return {
				key: profile.id,
				label: profile.name ?? "",
			};
		});
		return (
			<PaperTitle>
				<Stack spacing={3}>
					<SelectStatus status={invitationStatus} onChangeStatus={handleChangeStatus} defaultValue={participant.invitationStatus} />
					<Controller
						name={"profileId"}
						control={control}
						render={({ field: { value }, ...field }) => (
							<FormControlSelect {...field} label={translations.profile} onChange={handleChangeProfile} isNullable={true} value={value} items={items} />
						)}
					/>
					{participant.highestRankPrivilege?.name && (
						<Stack>
							<FieldLabel label={translations.label.highestRankPrivilege} />
							<Typography>{participant.highestRankPrivilege.name}</Typography>
						</Stack>
					)}
				</Stack>
			</PaperTitle>
		);
	}
};

export default ParticipantProfileCard;
