import { type DtoMailMessage, EmailDetailContent, NoData } from "@key4-front-library/core";
import { Divider, Stack, Typography } from "@mui/material";

import { ParticipantMailDetailHeader } from "./ParticipantMailDetailHeader";

interface ParticipantMailDetailsProps {
	mail: DtoMailMessage | null;
}

export const ParticipantMailDetail = (props: ParticipantMailDetailsProps) => {
	const { mail } = props;

	if (!mail) {
		return <NoData />;
	}

	return (
		<Stack divider={<Divider sx={{ opacity: "0.7" }} flexItem />} spacing={2}>
			<ParticipantMailDetailHeader mail={mail} />
			<Typography variant={"h6"}>{mail.subject}</Typography>
			<EmailDetailContent mail={mail} />
		</Stack>
	);
};
