import type * as React from "react";

import type { DataCypress, DtoParticipantGet } from "@key4-front-library/core";
import { Container, Grid, Skeleton, Stack, TablePagination } from "@mui/material";

import ParticipantCard from "./ParticipantCard";

type Props = DataCypress & {
	isLoading: boolean;
	page: number;
	pageSize: number;
	totalRowCount: number;
	handleChangePage: (page: number) => void;
	handleChangePageSize: (pageSize: number) => void;
	rowsPerPageOptions: Array<number>;
	handleParticipantClick: (participant: DtoParticipantGet) => void;
	handleParticipantDoubleClick: (participant: DtoParticipantGet) => void;
	participants: Array<DtoParticipantGet>;
};

const ParticipantListCard = (props: Props) => {
	const {
		isLoading,
		page,
		pageSize,
		totalRowCount,
		handleChangePage,
		handleChangePageSize,
		rowsPerPageOptions,
		handleParticipantClick,
		handleParticipantDoubleClick,
		participants,
		dataCypressID,
	} = props;

	if (isLoading) {
		return (
			<Container maxWidth={"xl"}>
				{[...Array(10).keys()].map((item) => (
					<Skeleton key={item} animation="wave" width={"xl"} height={50} />
				))}
			</Container>
		);
	}

	return (
		<Stack>
			<Grid container spacing="2rem" pb="2rem">
				{!isLoading &&
					participants &&
					participants.map((participant, index) => {
						return (
							<Grid key={index} px={{ xs: "1.5rem", sm: "0" }} item xs={12} md={6} lg={4} xl={3}>
								<ParticipantCard
									handleParticipantClick={handleParticipantClick}
									handleParticipantDoubleClick={handleParticipantDoubleClick}
									participant={participant}
									dataCypressID={dataCypressID ? `${dataCypressID}-cards_list-card_${participant.id}` : undefined}
								/>
							</Grid>
						);
					})}
			</Grid>
			<TablePagination
				component={"div"}
				count={totalRowCount}
				onPageChange={(_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
					handleChangePage(newPage);
				}}
				page={page}
				rowsPerPage={pageSize}
				rowsPerPageOptions={rowsPerPageOptions}
				onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
					handleChangePageSize(Number.parseInt(event.target.value, 10));
				}}
				showFirstButton
				showLastButton
				SelectProps={
					{
						"data-cy": dataCypressID ? `${dataCypressID}-pagination-select_rows_per_page` : undefined,
					} as any
				}
				backIconButtonProps={
					{
						"data-cy": dataCypressID ? `${dataCypressID}-pagination_button_back` : undefined,
					} as any
				}
				nextIconButtonProps={
					{
						"data-cy": dataCypressID ? `${dataCypressID}-pagination_button_next` : undefined,
					} as any
				}
				data-cy={dataCypressID ? `${dataCypressID}-pagination` : undefined}
			/>
		</Stack>
	);
};

export default ParticipantListCard;
