import type { SizeProp } from "@fortawesome/fontawesome-svg-core";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import { Box } from "@mui/material";
import type { SxProps } from "@mui/system";

import { Icon } from "./Icon";

export interface IK4StatusBullet {
	color: string;
	size?: string;
	sx?: SxProps;
}

const K4StatusBullet = (props: IK4StatusBullet & dataCypressProp) => {
	const { color, size = "1x", dataCypressID } = props;

	return (
		<Box sx={{ color, pt: 0 }} className="circle" display="flex" flexDirection="row" data-cy={dataCypressID}>
			<Icon prefix="fas" iconName="circle" size={size as SizeProp} />
		</Box>
	);
};

export default K4StatusBullet;
