import { t } from "i18next";
import type { MouseEventHandler } from "react";

import K4SplitButtonExport, { type K4SplitButtonExportItem } from "@infrastructure/components/interface/button/K4SplitButtonExport";

interface Props {
	items: Array<K4SplitButtonExportItem>;
	isDisabled?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement>;
}

const K4SplitButtonExportDecorated = (props: Props) => {
	const { isDisabled = false, onClick, items } = props;

	return <K4SplitButtonExport label={t("old.common.button.export")} items={items} isDisabled={isDisabled} onClick={onClick} />;
};

export default K4SplitButtonExportDecorated;
