import type { DtoCustomFieldFormV01, TabProps } from "@key4-front-library/core";
import { participantsTabs } from "@pages";

export const customFormsToTabs = (customForms: DtoCustomFieldFormV01[]): Array<TabProps> => [
	...(customForms?.map((customForm) => ({
		label: customForm.label,
		to: customForm.key ?? "",
	})) ?? []),
	...participantsTabs(),
];
