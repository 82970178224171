import type { MouseEventHandler } from "react";

import { Icon } from "@infrastructure/components/interface/icons/Icon";
import { Button } from "@mui/material";

interface Props {
	label: string;
	isDisabled?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement>;
}

const K4ButtonAdd = (props: Props) => {
	const { label, isDisabled = false, onClick } = props;
	return (
		<Button color={"primary"} disabled={isDisabled} onClick={onClick} variant="contained" startIcon={<Icon prefix="far" iconName={"plus"} size={"1x"} />}>
			{label}
		</Button>
	);
};

export default K4ButtonAdd;
