import { t } from "i18next";

import { ButtonCancel, ButtonSave } from "@infrastructure/components/interface/uikit/K4Button";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import { Box, type Breakpoint, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";

interface Props {
	title?: string;
	children: JSX.Element;
	isOpen: boolean;
	maxWidth?: Breakpoint;
	isFullWidth?: boolean;
	handleModaleClose: () => void;
	handleSubmit: () => void;
}

const SaveModal = (props: Props & dataCypressProp) => {
	const { title, children, isOpen = false, maxWidth = "md", isFullWidth = true, handleModaleClose, handleSubmit, dataCypressID } = props;

	const translations = {
		buttons: {
			save: t("old.form.buttons.save"),
			cancel: t("old.form.buttons.cancel"),
		},
	};

	return (
		<Dialog open={isOpen} maxWidth={maxWidth} fullWidth={isFullWidth}>
			{title && <DialogTitle data-cy={dataCypressID ? `${dataCypressID}-title` : undefined}>{title}</DialogTitle>}
			<DialogContent>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
					}}
				>
					{children}
				</Box>
			</DialogContent>
			<DialogActions>
				<Stack direction={"row"} spacing=".8rem" sx={{ px: "2.5rem", pb: "1rem" }}>
					<ButtonCancel
						label={translations.buttons.cancel}
						onClick={handleModaleClose}
						dataCypressID={dataCypressID ? `${dataCypressID}-cancel_button` : undefined}
					/>
					<ButtonSave label={translations.buttons.save} onClick={handleSubmit} dataCypressID={dataCypressID ? `${dataCypressID}-save_button` : undefined} />
				</Stack>
			</DialogActions>
		</Dialog>
	);
};
export default SaveModal;
