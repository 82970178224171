import {
	type DtoCostCenter,
	type DtoCostCenterGet,
	getFileName,
	queryFilters,
	queryStringPagination,
	queryStringSorts,
	Services,
	sieveStringFiltersPageList,
	type TPagination,
	type TypeApiResponsePagined,
} from "@key4-front-library/core";

/**
 * Get List Pagined of Cost Centers
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param search Search word
 * @param sorts Sort fields and directions
 * @param pagination pagination properties
 * @typedef TypeApiResponsePaginedPagination Type of Pagination
 * @returns Pagined list of Cost Center
 * @typedef TypeApiResponsePagined Type of Pagined response
 * @typedef DtoCostCenterGet Dto of Cost Center
 */
const getList = async (
	clientId: string,
	eventId: string,
	search: string | undefined,
	sorts: Array<string>,
	pagination?: TPagination,
): Promise<TypeApiResponsePagined<Array<DtoCostCenterGet>>> =>
	await Services.Events.Registration.CostCentersService.getListPagined(clientId, eventId, [
		...queryStringPagination(pagination),
		...queryStringSorts(sorts),
		...queryFilters(sieveStringFiltersPageList(["name"], search)),
	]);

/**
 * Create a new Cost center
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param costCenter Cost center to create
 * @typedef DtoCostCenter Dto of Cost Center for writing
 * @returns ID of Cost center created
 */
const create = async (clientId: string, eventId: string, costCenter: DtoCostCenter) =>
	await Services.Events.Registration.CostCentersService.post(clientId, eventId, costCenter);

/**
 * Update a Cost center
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param costCenter Cost center to update
 * @typedef DtoCostCenterGet Dto of Cost Center
 * @returns Success boolean
 */
const update = async (clientId: string, eventId: string, costCenter: DtoCostCenterGet) =>
	await Services.Events.Registration.CostCentersService.put(clientId, eventId, costCenter.id, {
		name: costCenter.name,
	});

/**
 * Delete a Cost center
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param costCenterId Current Cost center ID
 * @returns Success boolean
 */
const deleteEntity = async (clientId: string, eventId: string, costCenterId: string) =>
	await Services.Events.Registration.CostCentersService.deleteEntity(clientId, eventId, costCenterId);

/**
 * Get Export of Cost Centers
 * @param clientId Current client ID
 * @param eventId Current event ID
 * @param eventCode Current event Code
 * @param filename Filename of export
 * @returns Content of the file to download
 */
const getExport = async (clientId: string, eventId: string, eventCode: string, filename: string) =>
	await Services.Events.Registration.CostCentersService.getExport(clientId, eventId, getFileName(eventCode, filename));

/**
 * Cost Center Controller
 */
const CostCenterController = {
	getList,
	create,
	update,
	deleteEntity,
	getExport,
};

export default CostCenterController;
