import axios from "axios";
import { t } from "i18next";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { type Control, useController } from "react-hook-form";

import StringService from "@infrastructure/services/string/string.service";
import type { DataCypress, DtoRole } from "@key4-front-library/core";
import { Box, Grid, IconButton, Paper, TextField } from "@mui/material";

import { Icon } from "../icons/Icon";

interface FamilyStyle {
	family: string;
	style: string;
}

interface familyStylesByLicense {
	pro: Array<FamilyStyle>;
}

interface FontAwesomeIconResponse {
	label: string;
	id: string;
	familyStylesByLicense: familyStylesByLicense;
}

const styles = {
	iconPickerLine: {
		display: "flex",
		position: "relative",
		width: "100%",
	},
	iconContainer: {
		width: "56px",
		height: "56px",
		borderRadius: "8px",
		border: "1px solid #bbb",
		textAlign: "center",
	},
	iconDisplayer: {
		fontSize: "2.4em",
		minWidth: "57px",
	},
	iconFinderAutocomplete: {
		marginLeft: "16px",
		gridRow: 2,
		width: "100%",
	},
	iconProposal: {
		cursor: "pointer",
		padding: "4px",
		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.04)",
		},
	},
	iconProposalTitle: {
		marginLeft: "4px",
	},
	deleteIcon: {
		p: "5px",
		marginTop: "14px",
		width: "35px",
	},
	dropdownIcon: {
		position: "fixed",
		zIndex: 1000,
	},
};

type Props = DataCypress & {
	icon: string | undefined;
	label: string | undefined;
	control: Control<DtoRole>;
};

const IconPicker = (props: Props) => {
	const { label, control, dataCypressID } = props;
	const { field } = useController({ control, name: "icon" });

	const { enqueueSnackbar } = useSnackbar();

	const translations = {
		label: label ? label : t("iconpicker:label"),
		placeholder: t("iconpicker:placeholder"),
		error: t("iconpicker:error"),
	};

	const [iconList, setIconList] = React.useState<Array<FontAwesomeIconResponse>>([]);
	const [iconFinder, setIconFinder] = React.useState<string>(props.icon ?? "");
	const [selectedIcon, setSelectedIcon] = React.useState<string | undefined>(props.icon ?? "");

	// #region 'Function'
	const reset = () => {
		setIconList([]);
		setIconFinder("");
		setSelectedIcon("");
		field.onChange("");
	};

	// Call to fontawesome api graphql with a query in order to search icons by them name matching.
	// A filter will only use classic and light icons. Others will be ignored.
	const callSearchFontAwesomeIcon = (): void => {
		axios({
			url: "https://api.fontawesome.com/",
			method: "post",
			headers: { "Content-Type": "application/json" },
			data: {
				query: `query { search(version: "6.2.0", query: "${iconFinder}", first: 10) {  label id familyStylesByLicense { pro {family style} } } }`,
				variables: {},
			},
		})
			.then((response) => {
				const icons: Array<FontAwesomeIconResponse> = response.data.data.search;
				const filteredRegularAndClassicIcons: Array<FontAwesomeIconResponse> = icons.filter((icon) =>
					icon.familyStylesByLicense.pro.some((data) => data.family === "classic" && data.style === "light"),
				);
				setIconList(filteredRegularAndClassicIcons);
			})
			.catch(() => {
				enqueueSnackbar(translations.error, {
					variant: "error",
				});
			});
		return;
	};

	useEffect(() => {
		if (iconFinder === "") {
			setIconList([]);
		} else {
			callSearchFontAwesomeIcon();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [iconFinder]);

	useEffect(() => {
		// Allow to remove complety the selected icon if the icon name has been changed.
		if (selectedIcon !== "" && iconFinder !== selectedIcon) {
			reset();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [iconFinder]);

	return (
		<Grid item xs={12}>
			<Box sx={styles.iconPickerLine}>
				{/* Display selected icon */}
				<Box sx={styles.iconContainer}>
					<Box sx={styles.iconDisplayer}>
						<i
							className={`fa-light fa-${selectedIcon}`}
							data-cy={
								dataCypressID ? `${dataCypressID}-icon_preview${selectedIcon ? StringService.transformKebabToSnake(`_${selectedIcon}`) : ""}` : undefined
							}
						/>
					</Box>
				</Box>

				{/* Display selected icon */}
				<Box sx={styles.iconFinderAutocomplete}>
					<TextField
						id="icon-searcher"
						label={translations.label}
						placeholder={translations.placeholder}
						value={iconFinder}
						fullWidth
						onChange={(e) => {
							setIconFinder(e.target.value);
						}}
						inputProps={{
							maxLength: 7,
							"data-cy": dataCypressID ? `${dataCypressID}-icon_field` : undefined,
						}}
					/>

					{/* Display the list of icon */}
					{selectedIcon === "" && (
						<Paper sx={styles.dropdownIcon}>
							{iconList.map((icon) => {
								return (
									<Box
										key={`id-${icon.id}`}
										sx={styles.iconProposal}
										onClick={() => {
											setSelectedIcon(icon.id);
											setIconFinder(icon.id);
											setIconList([]);
											field.onChange(icon.id);
										}}
										data-cy={dataCypressID ? `${dataCypressID}-icon_field-menu_item_${StringService.transformKebabToSnake(icon.id)}` : undefined}
									>
										<i
											key={icon.id}
											className={`fa-light fa-${icon.id}`}
											data-cy={dataCypressID ? `${dataCypressID}-icon_field-menu_item_${StringService.transformKebabToSnake(icon.id)}-icon` : undefined}
										/>
										<span
											style={{ marginLeft: "8px" }}
											data-cy={dataCypressID ? `${dataCypressID}-icon_field-menu_item_${StringService.transformKebabToSnake(icon.id)}-label` : undefined}
										>
											{icon.label}
										</span>
									</Box>
								);
							})}
						</Paper>
					)}
				</Box>

				{/* Display icon for reset */}
				{selectedIcon !== "" && (
					<Box>
						<IconButton
							sx={styles.deleteIcon}
							aria-label="reset-search"
							color="primary"
							onClick={() => {
								reset();
							}}
							data-cy={dataCypressID ? `${dataCypressID}-icon_field-erase_button` : undefined}
						>
							<Icon prefix="far" iconName="xmark" />
						</IconButton>
					</Box>
				)}
			</Box>
		</Grid>
	);
};

export default IconPicker;
