import { Brands, CFEditorCustomField, CustomFieldDefinition, LegalNotice, TermsOfSale, UsersPage } from "@key4-front-library/core";
import PageDashboard from "@presentation/PageDashboard";
import { PagePrivilegeDetails } from "@presentation/PagePrivilegeDetails";
import PagePrivileges from "@presentation/PagePrivileges";
import PagePrivilegesAnalytics from "@presentation/PagePrivilegesAnalytics";
import { PagePrivilegesMatrix } from "@presentation/PagePrivilegesMatrix";
import PageSettings from "@presentation/PageSettings";
import PageSettingsCostCenters from "@presentation/PageSettingsCostCenters";
import PageSettingsProfiles from "@presentation/PageSettingsProfiles";
import PageSettingsRoles from "@presentation/PageSettingsRoles";
import BulkActionParticipant from "@presentation/participants/BulkActionParticipants";
import PageParticipants from "@presentation/participants/PageParticipants";

export const Pages = {
	PageDashboard,
	PageParticipants,
	PagePrivilegesAnalytics,
	PagePrivilegesMatrix,
	PagePrivilegeDetails,
	PagePrivileges,
	PageSettingsCostCenters,
	PageSettingsProfiles,
	PageSettingsRoles,
	PageSettings,
	PageCFEditorCustomField: CFEditorCustomField,
	PagesMentions: {
		PageBrands: Brands,
		PageLegalNotice: LegalNotice,
		PageTermsOfSale: TermsOfSale,
	},
	BulkActions: {
		Participant: BulkActionParticipant,
	},
	Users: { _: UsersPage },
};

export const Providers = {
	CustomFields: {
		Definition: CustomFieldDefinition,
	},
};
