import { t } from "i18next";
import { FixedSizeList, type ListChildComponentProps } from "react-window";

import { ApplicationColors } from "@application/Configurations/color.configuration";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import { pickTextColorBasedOnBgColor } from "@infrastructure/services/color/color.service";
import { getSimilarityBetweenTwoArraysWithId } from "@infrastructure/services/helpers/arrayFunctions";
import type { DtoRole } from "@key4-front-library/core";
import { Autocomplete, Box, Checkbox, Chip, TextField } from "@mui/material";

interface Props {
	label: string;
	roles: Array<DtoRole>;
	handleRoleChange: (e: React.SyntheticEvent, selectedRoles: ReadonlyArray<DtoRole>) => void;
	defaultRolesValues: Array<DtoRole>;
}

const renderOption = (props: ListChildComponentProps) => {
	const { data } = props;
	const { role, selected } = data;
	return (
		<>
			<Checkbox style={{ marginRight: 8 }} checked={selected} />
			{role.name}
		</>
	);
};

const RolesSelect = (props: Props & dataCypressProp) => {
	const { label, roles, handleRoleChange, defaultRolesValues, dataCypressID } = props;

	// need to get the references of roles objects
	// to define the default values of autocomplete
	// can't do : defaultRolesValues.current = formData.roles
	const defaultRoles = getSimilarityBetweenTwoArraysWithId(roles, defaultRolesValues);

	const translations = {
		search: t("old.form.placeholders.search"),
	};

	return (
		<Autocomplete
			multiple
			id="tags-outlined"
			options={roles}
			getOptionLabel={(role) => role.name}
			defaultValue={defaultRoles}
			disableCloseOnSelect
			sx={{ maxWidth: "100%" }}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					placeholder={translations.search}
					inputProps={{
						...params.inputProps,
						"data-cy": dataCypressID ? dataCypressID : undefined,
					}}
				/>
			)}
			renderTags={(selected: Array<DtoRole>, getTagProps) => (
				<Box
					sx={{
						display: "flex",
						flexWrap: "wrap",
						gap: 0.5,
					}}
				>
					{selected.map((role, index) => (
						<Chip
							{...getTagProps({ index })}
							label={role.name}
							sx={{
								backgroundColor: role.color,
								"& .MuiChip-label": {
									color: pickTextColorBasedOnBgColor(role.color!).textColor,
								},
								"& .MuiChip-deleteIcon": {
									color: {
										color: pickTextColorBasedOnBgColor(role.color!).isBackgroundDark ? ApplicationColors.white.main : "",
									},
								},
							}}
							// necessary to make handleDelete works inside Select MUI component
							onMouseDown={(event) => {
								event.stopPropagation();
							}}
							data-cy={dataCypressID ? `${dataCypressID}-chip_${role.id}` : undefined}
						/>
					))}
				</Box>
			)}
			renderOption={(props, role, { selected }) => (
				<li {...props} data-cy={dataCypressID ? `${dataCypressID}-select_item_${role.id}` : undefined}>
					<FixedSizeList
						// can't use em for height prop instead of pixel with react window
						height={42}
						width={"100%"}
						itemData={{ role, selected }}
						itemSize={1}
						itemCount={1}
						overscanCount={5}
					>
						{renderOption}
					</FixedSizeList>
				</li>
			)}
			onChange={handleRoleChange}
		/>
	);
};

export default RolesSelect;
