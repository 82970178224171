import { t } from "i18next";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";

import DashboardParticipantsTotalCount from "@application/Components/dashboard/count/DashboardParticipantsTotalCount";
import DashboardInvitations from "@application/Components/dashboard/invitations/DashboardInvitations";
import DashboardRoleEditModal from "@application/Components/dashboard/roleEditModal/DashboardRoleEditModal";
import DashboardRoles from "@application/Components/dashboard/roles/DashboardRoles";
import DashboardController from "@application/Controllers/DashboardController";
import RoleController from "@application/Controllers/RoleController";
import SnackbarHelper from "@application/Helpers/SnackbarHelper";
import Loading from "@infrastructure/components/interface/loading/Loading";
import Title from "@infrastructure/components/interface/titles/Title";
import { EButtonColor, EButtonVariant, type IK4ButtonProps } from "@infrastructure/components/interface/uikit/K4Button";
import {
	type DtoDashboardParticipant,
	type DtoDashboardRole,
	type DtoEvent,
	type DtoReOrder,
	type DtoRole,
	ErrorAPI,
	PeriodContainer,
	useContextModule,
	useLoadFullData,
} from "@key4-front-library/core";
import { Box, Grid } from "@mui/material";

const PageDashboard = () => {
	const { client, event } = useContextModule();

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [participants, setParticipants] = useState<DtoDashboardParticipant>();
	const [error, setError] = useState();

	const [isManage, setIsManage] = useState<boolean>(false);
	const [isModaleOpen, setIsModaleOpen] = useState<boolean>(false);

	const [dashboardRoles, setDashboardRoles] = useState<Array<DtoDashboardRole>>([]);
	const [roleToEdit, setRoleToEdit] = useState<DtoRole>();
	const [unpinnedRoles, setUnpinnedRoles] = useState<Array<DtoRole>>([]);
	const [pinnedRoleClicked, setPinnedRoleClicked] = useState<DtoDashboardRole>();

	const translations = {
		title: t("old.registration.dashboard.title"),
		charts: {
			bar: {
				title: t("old.registration.dashboard.charts.bar.title"),
				serieName: t("old.registration.dashboard.charts.bar.serieName"),
			},
			donut: t("old.registration.dashboard.charts.donut.title"),
		},
		buttons: {
			save: t("old.form.buttons.save"),
			manage: t("old.form.buttons.manage"),
		},
		editionModal: {
			title: t("old.registration.settings.roles.edition.title.modification"),
			returnMessages: {
				success_modification: t("old.registration.settings.roles.edition.returnMessages.success_modification"),
			},
		},
	};

	const getRoles = async (page: number, limit: number) => {
		return await RoleController.getListPagined(client.id, event.id, "", [], {
			page,
			pageSize: limit,
		});
	};

	const { data: roles } = useLoadFullData(getRoles);

	const handleSaveButtonClick = async () => {
		if (dashboardRoles !== participants?.roles) {
			const roleCardsIds = dashboardRoles.map(({ id }: any) => id) as DtoReOrder;
			await DashboardController.updateRolesOrders(client.id, event.id, roleCardsIds)
				.then((response: any) => {
					if (response instanceof ErrorAPI) {
						const error = response.message?.toString();
						enqueueSnackbar(error, SnackbarHelper.error);
						return;
					}
				})
				.catch((responseError) => {
					enqueueSnackbar(responseError, SnackbarHelper.error);
				});
		}
		setIsManage(false);
	};

	const handleManageButtonClick = () => {
		setIsManage(true);
	};

	const handleModaleClose = () => {
		setIsModaleOpen(false);
	};

	const handleSaveRole = (_role: DtoDashboardRole) => {
		const { id, name, color, icon, scope, type } = _role;
		const _data = {
			id,
			name,
			color,
			icon: icon ?? "",
			scope,
			type,
		};
		RoleController.update(client.id, event.id, _data)
			.then((response: any) => {
				if (response instanceof ErrorAPI) {
					const error = response.message?.toString();
					enqueueSnackbar(error);
					return;
				}
				setDashboardRoles((prevRoles) => {
					const indexObjectToUpdate = prevRoles.findIndex((object: DtoDashboardRole) => object.id === _role.id);
					if (indexObjectToUpdate !== -1) {
						return [...prevRoles.slice(0, indexObjectToUpdate), _role, ...prevRoles.slice(indexObjectToUpdate + 1)];
					}
					return prevRoles;
				});
			})
			.catch((responseError) => {
				enqueueSnackbar(responseError, SnackbarHelper.error);
			})
			.finally(() => {
				setIsModaleOpen(false);
			});
	};

	const saveButton: IK4ButtonProps = {
		label: translations.buttons.save,
		color: EButtonColor.secondary,
		startIcon: "fa-save",
		handleClick: handleSaveButtonClick,
	};

	const editButton: IK4ButtonProps = {
		label: translations.buttons.manage,
		variant: EButtonVariant.containedAlt,
		startIcon: "fa-gear",
		handleClick: handleManageButtonClick,
	};

	useEffect(() => {
		const fetchData = async (_event: DtoEvent) => {
			setIsLoading(true);
			await DashboardController.get(client.id, _event.id)
				.then((response) => {
					setParticipants(response);
					setDashboardRoles(response.roles);
				})
				.catch((responseError) => {
					setError(responseError);
					enqueueSnackbar(responseError, SnackbarHelper.error);
				})
				.finally(() => {
					setIsLoading(false);
				});
		};

		fetchData(event);
	}, [event]);

	useEffect(() => {
		if (participants && dashboardRoles && roles) {
			setUnpinnedRoles(roles.filter((role) => !dashboardRoles.map((_role) => _role.id).includes(role.id)));
		}
	}, [event, participants, dashboardRoles, roles]);

	useEffect(() => {
		if (pinnedRoleClicked) {
			const roleIsUnpinned =
				unpinnedRoles.filter((role: DtoRole) => {
					return role.id === pinnedRoleClicked.id;
				}).length > 0;

			const addUnpinnedRoleToPinnedOnes = () => {
				const roleCardsTmp = [...dashboardRoles];
				roleCardsTmp.push(pinnedRoleClicked);
				setDashboardRoles(roleCardsTmp);
				DashboardController.pinRole(client.id, event.id, pinnedRoleClicked.id)
					.then((response: any) => {
						if (response instanceof ErrorAPI) {
							const error = response.message?.toString();
							enqueueSnackbar(error, SnackbarHelper.error);
							return;
						}
					})
					.catch((responseError) => {
						enqueueSnackbar(responseError, SnackbarHelper.error);
					});
			};

			const addPinnedRoleToUnpinnedOnes = () => {
				const unpinnedRolesTmp = [...unpinnedRoles];
				unpinnedRolesTmp.unshift(pinnedRoleClicked);
				setDashboardRoles(dashboardRoles.filter((role: DtoRole) => role.id !== pinnedRoleClicked.id));
				DashboardController.unPinRole(client.id, event.id, pinnedRoleClicked.id)
					.then((response: any) => {
						if (response instanceof ErrorAPI) {
							const error = response.message?.toString();
							enqueueSnackbar(error, SnackbarHelper.error);
							return;
						}
					})
					.catch((responseError) => {
						enqueueSnackbar(responseError, SnackbarHelper.error);
					});
			};

			if (roleIsUnpinned) {
				addUnpinnedRoleToPinnedOnes();
			} else {
				addPinnedRoleToUnpinnedOnes();
			}
		}
	}, [pinnedRoleClicked]);

	if (error) {
		throw error;
	}
	if (isLoading) {
		return <Loading />;
	}

	return (
		<Box>
			<Title
				title={event.name ?? ""}
				buttons={isManage ? [saveButton] : [editButton]}
				reference={<PeriodContainer dateStartIso={event.startDate} dateEndIso={event.endDate} />}
			/>
			<Grid container spacing={3.75}>
				{participants && <DashboardParticipantsTotalCount title={translations.title} count={participants.totalParticipants} />}

				{participants && <DashboardInvitations title={translations.charts.donut} serie={participants.invitationsStatus} />}
			</Grid>
			<Grid container mt={2} spacing={3.75}>
				<DashboardRoles
					roles={dashboardRoles}
					unpinnedRoles={unpinnedRoles}
					isManage={isManage}
					setRoles={setDashboardRoles}
					setIsModaleOpen={setIsModaleOpen}
					setRoleToEdit={setRoleToEdit}
					setPinnedRoleClicked={setPinnedRoleClicked}
				/>
			</Grid>

			<DashboardRoleEditModal
				open={isModaleOpen}
				title={translations.editionModal.title}
				role={roleToEdit}
				onSave={handleSaveRole}
				onClose={handleModaleClose}
			/>
		</Box>
	);
};

export default PageDashboard;
