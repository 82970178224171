import type { DtoProfile } from "@key4-front-library/core";
import ControllersBo from "@key4-front-library/core/Bo/Controllers";
import { useQuery } from "@tanstack/react-query";

const useEntity = () => {
	const { readList: readListDtoProfile } = ControllersBo.ProfileController.useEntity();

	const readList = () => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: ["role", "list"],
			queryFn: async (): Promise<Array<DtoProfile>> => (await readListDtoProfile()) ?? [],
		});
		return { data, isLoading, refetch };
	};

	return { readList };
};

const ProfileHook = {
	useEntity,
};

export default ProfileHook;
