import Period from "@infrastructure/components/interface/period/Period";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import DateService from "@infrastructure/services/dates/date.service";
import { Culture, Cultures, useAuthenticationContext } from "@key4-front-library/core";

interface Props {
	dateStartIso: string;
	dateEndIso: string;
}
const PeriodContainer = (props: Props & dataCypressProp) => {
	const { dateStartIso, dateEndIso, dataCypressID } = props;

	const { user } = useAuthenticationContext();
	const culture = Cultures[user.locale].locale;

	const startDateLocaleStringFormatDependingOnEndDate = (
		dateFromIso: string,
		dateToIso: string,
		culture: string = Culture[0].culture,
	): { dateStart: string; dateEnd: string } => {
		const datetimeFrom = DateService.Convert.GetDateFromIsoString(dateFromIso);
		const datetimeTo = DateService.Convert.GetDateFromIsoString(dateToIso);
		const dateObjectFrom = datetimeFrom.toObject();
		const dateObjectTo = datetimeTo.toObject();

		const dateEnd = DateService.Format.FullDate({
			date: datetimeTo,
			culture,
		});
		if (dateObjectFrom.year !== dateObjectTo.year) {
			// years are different: display start date day, month and year
			return {
				dateStart: DateService.Format.FullDate({
					date: datetimeFrom,
					culture,
				}),
				dateEnd,
			};
		}
		if (dateObjectFrom.month !== dateObjectTo.month) {
			// months are different: display start date day and month
			return {
				dateStart: datetimeFrom.setLocale(culture).toLocaleString({
					month: "long",
					day: "numeric",
				}),
				dateEnd,
			};
		}
		// years and months are the same: display start date day only
		return {
			dateStart: datetimeFrom.setLocale(culture).toLocaleString({
				day: "numeric",
			}),
			dateEnd,
		};
	};
	const period = startDateLocaleStringFormatDependingOnEndDate(dateStartIso, dateEndIso, culture);

	return <Period dateFrom={period.dateStart} dateTo={period.dateEnd} dataCypressID={dataCypressID} />;
};

export default PeriodContainer;
