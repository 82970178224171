import { t } from "i18next";
import { NumericFormat as NumberFormat } from "react-number-format";

import { TextColors } from "@application/Configurations/color.configuration";
import HooksApp from "@application/Hooks";
import { type DataCypress, type DtoRole, getDataCypressID } from "@key4-front-library/core";
import { EnumRoleType } from "@key4-front-library/core/Enums";
import { Box, Stack, Typography } from "@mui/material";

const styles = {
	ellipsis: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "-webkit-box",
		WebkitLineClamp: 1,
		WebkitBoxOrient: "vertical",
	},
};

type Props = DtoRole &
	DataCypress & {
		value: number | null;
	};

const Role = (props: Props) => {
	const { name, color, icon, type, value, dataCypressID } = props;

	const { readMode } = HooksApp.AuthHook.useEntity();
	const mode = readMode();
	let typeDescription;

	const translations = {
		role: {
			type: {
				programme: t("old.common.entity.role.type.programme"),
				manual: t("old.common.entity.role.type.manual"),
				external: t("old.common.entity.role.type.external"),
				query: t("old.common.entity.role.type.query"),
			},
		},
		format: {
			thousandSeparator: t("old.common.format.number.thousand-separator"),
		},
	};
	switch (type) {
		case EnumRoleType.MANUAL:
			typeDescription = translations.role.type.manual;
			break;
		case EnumRoleType.PROGRAMME:
			typeDescription = translations.role.type.programme;
			break;
		case EnumRoleType.EXTERNAL:
			typeDescription = translations.role.type.external;
			break;
		case EnumRoleType.QUERY:
			typeDescription = translations.role.type.query;
			break;
	}

	return (
		<Stack direction={"row"} spacing={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
			<Stack direction={"column"}>
				<Stack direction={"row"} spacing={4} display={"flex"} alignItems={"center"}>
					{value !== null && (
						<Typography sx={{ color, fontWeight: "700" }} variant={"h3"}>
							<NumberFormat
								value={+value}
								displayType={"text"}
								thousandSeparator={translations.format.thousandSeparator}
								data-cy={getDataCypressID(dataCypressID, ["value"])}
							/>
						</Typography>
					)}
					<Typography sx={{ color, ...styles.ellipsis, fontWeight: "700" }} variant={"h5"} data-cy={getDataCypressID(dataCypressID, ["name"])}>
						{name}
					</Typography>
				</Stack>
				<Typography sx={{ ...styles.ellipsis, fontWeight: "500" }} variant={"h5"} color="text.disabled" data-cy={getDataCypressID(dataCypressID, ["type"])}>
					{typeDescription}
				</Typography>
			</Stack>
			<Box
				component={"i"}
				key={`icon${name}`}
				className={`fa-light fa-${icon}`}
				sx={{
					fontSize: "4em",
					color: mode === "light" ? TextColors.light.secondary : TextColors.dark.primary,
				}}
				data-cy={getDataCypressID(dataCypressID, ["icon"])}
			></Box>
		</Stack>
	);
};

export default Role;
