import {
	type MailSenderRead,
	type MailSenderWrite,
	type Paginated,
	type PathEntity,
	type ServiceArgs,
	deleteResponse,
	get,
	getPaginated,
	postCreate,
	put,
} from "@key4-front-library/core";

const module = "registration";
const rootEntities = (id?: string): Array<PathEntity> => [{ entity: "mail-senders", id }];

export interface GetRegistrationMailSendersProps extends ServiceArgs {
	clientId: string;
	operationId: string;
}

export const getRegistrationMailSenders = async (args: GetRegistrationMailSendersProps): Promise<Paginated<MailSenderRead>> => {
	return await getPaginated<MailSenderRead>({
		module,
		entities: rootEntities(),
		...args,
	});
};

export interface GetRegistrationMailSenderProps extends ServiceArgs {
	clientId: string;
	operationId: string;
	id: string;
}

export const getRegistrationMailSender = async (args: GetRegistrationMailSenderProps): Promise<MailSenderRead> => {
	const { id, ...others } = args;
	return await get<MailSenderRead>({
		module,
		entities: rootEntities(id),
		...others,
	});
};

export interface PostRegistrationMailSenderProps extends ServiceArgs {
	clientId: string;
	operationId: string;
	body: MailSenderWrite;
}

export const postRegistrationMailSender = async (args: PostRegistrationMailSenderProps): Promise<string> => {
	const { body, ...others } = args;
	return await postCreate<MailSenderWrite>(
		{
			module,
			entities: rootEntities(),
			...others,
		},
		body,
	);
};

export interface PutRegistrationMailSenderProps extends ServiceArgs {
	clientId: string;
	operationId: string;
	id: string;
	body: MailSenderWrite;
}

export const putRegistrationMailSender = async (args: PutRegistrationMailSenderProps): Promise<boolean> => {
	const { id, body, ...others } = args;
	return await put<MailSenderWrite>(
		{
			module,
			entities: rootEntities(id),
			...others,
		},
		body,
	);
};

export interface DeleteRegistrationMailSenderProps extends ServiceArgs {
	clientId: string;
	operationId: string;
	id: string;
}

export const deleteRegistrationMailSender = async (args: DeleteRegistrationMailSenderProps): Promise<boolean> => {
	const { id, ...others } = args;
	return await deleteResponse({
		module,
		entities: rootEntities(id),
		...others,
	});
};
