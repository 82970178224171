import { t } from "i18next";

import { EFormAction } from "@domain/interfaces/form.interface";
import { ButtonCancel, ButtonSave } from "@infrastructure/components/interface/uikit/K4Button";
import type { dataCypressProp } from "@infrastructure/model/@types/qa/data-cypress-id";
import { Box, type Breakpoint, Dialog, DialogActions, DialogContent, Stack } from "@mui/material";

interface Props {
	action: EFormAction;
	open: boolean;
	maxWidth?: Breakpoint;
	isFullWidth?: boolean;
	handleModaleClose: () => void;
	handleAction: () => void;
}

const ConfirmationModal = (props: Props & dataCypressProp) => {
	const { action, open = false, maxWidth = "md", isFullWidth = true, handleModaleClose, handleAction, dataCypressID } = props;

	const form = {
		buttons: {
			save: t("old.form.buttons.confirm"),
			cancel: t("old.form.buttons.cancel"),
			create: t("old.form.buttons.create"),
		},
		labels: {
			edit: t("old.form.confirmationModal.edit"),
			delete: t("old.form.confirmationModal.delete"),
		},
	};

	let actionLabel;
	switch (action) {
		case EFormAction.DELETE:
			actionLabel = form.labels.delete;
			break;

		case EFormAction.EDIT:
			actionLabel = form.labels.edit;
			break;
		default:
			actionLabel = form.labels.edit;
			break;
	}

	return (
		<Dialog open={open} maxWidth={maxWidth} fullWidth={isFullWidth}>
			<DialogContent>
				<Box data-cy={dataCypressID ? `${dataCypressID}-label` : undefined}>{actionLabel}</Box>
			</DialogContent>
			<DialogActions>
				<Stack direction={"row"} mr="2rem" mb=".5rem" spacing=".8rem">
					<ButtonCancel
						label={form.buttons.cancel}
						dataCypressID={dataCypressID ? `${dataCypressID}-cancel_button` : undefined}
						onClick={handleModaleClose}
						sx={{ mr: 1 }}
					/>

					<ButtonSave label={form.buttons.save} dataCypressID={dataCypressID ? `${dataCypressID}-save_button` : undefined} onClick={handleAction} />
				</Stack>
			</DialogActions>
		</Dialog>
	);
};
export default ConfirmationModal;
