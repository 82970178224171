import Card from "@infrastructure/components/interface/card/Card";
import NumberThousandSeparator from "@infrastructure/components/interface/textFormat/NumberThousandSeparator";
import { IconOld } from "@key4-front-library/core/Bo/Components/Icon";
import { Grid, Stack, Typography } from "@mui/material";

interface Props {
	title: string;
	count: number;
}

const DashboardParticipantsTotalCount = (props: Props) => {
	const { count: totalParticipants, title } = props;

	return (
		<Grid
			item
			xs={12}
			md={6}
			lg={3}
			sx={{
				display: "flex",
			}}
		>
			<Card title={<span>{title}</span>}>
				<Stack direction={{ xs: "row", lg: "column", xl: "row" }} spacing={2} mt={{ xs: 2, lg: 0 }}>
					<IconOld prefix="far" iconName={"users"} size="5x" />
					<Typography
						sx={{
							fontWeight: "bold",
							fontSize: "50px",
						}}
					>
						<NumberThousandSeparator value={totalParticipants} />
					</Typography>
				</Stack>
			</Card>
		</Grid>
	);
};

export default DashboardParticipantsTotalCount;
