import { ApplicationColors } from "@application/Configurations/color.configuration";
import type { IStatus } from "@domain/model/status.interface";
import { EnumParticipantStatusInvitation } from "@key4-front-library/core";

export const invitationStatus: Array<IStatus> = [
	{
		value: EnumParticipantStatusInvitation.UNSENT,
		label: "old.registration.participantDetails.invitationStatus.status.unsent",
		bgColor: ApplicationColors.greyLight.main,
		fontColor: ApplicationColors.greyLight.contrastText,
	},
	{
		value: EnumParticipantStatusInvitation.WAITING_FOR_ANSWER,
		label: "old.registration.participantDetails.invitationStatus.status.waitingForAnswer",
		bgColor: ApplicationColors.blueLight.dark!,
		fontColor: ApplicationColors.blueLight.contrastText,
	},
	{
		value: EnumParticipantStatusInvitation.ACCEPTED,
		label: "old.registration.participantDetails.invitationStatus.status.accepted",
		bgColor: ApplicationColors.green.main,
		fontColor: ApplicationColors.green.contrastText,
	},
	{
		value: EnumParticipantStatusInvitation.DECLINED,
		label: "old.registration.participantDetails.invitationStatus.status.declined",
		bgColor: ApplicationColors.red.main,
		fontColor: ApplicationColors.red.contrastText,
	},
	{
		value: EnumParticipantStatusInvitation.CANCELLED,
		label: "old.registration.participantDetails.invitationStatus.status.cancelled",
		bgColor: ApplicationColors.veryDarkBlue.main,
		fontColor: ApplicationColors.veryDarkBlue.contrastText,
	},
];
