import {
	deleteRegistrationMailSender,
	getRegistrationMailSender,
	getRegistrationMailSenders,
	postRegistrationMailSender,
	putRegistrationMailSender,
} from "@api";
import {
	type MailSenderCreateRequest,
	type MailSenderDeleteRequest,
	type MailSenderRead,
	type MailSenderUpdateRequest,
	type Paginated,
	QUERY_KEYS_DOCUMENT,
	type UseMutationArgs,
	type UseQueryArgs,
} from "@key4-front-library/core";
import { type UseMutationResult, type UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";

export const useRegistrationMailSenders = (
	clientId: string,
	operationId: string,
	args: UseQueryArgs<Paginated<MailSenderRead>> = {},
): UseQueryResult<Paginated<MailSenderRead>> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	return useQuery<Paginated<MailSenderRead>>({
		queryKey: [...QUERY_KEYS_DOCUMENT.documentMailSenders, ...queryKeyPart, "Get", queryStrings, clientId, operationId],
		queryFn: ({ signal }) => getRegistrationMailSenders({ clientId, operationId, signal }),
		...others,
	});
};

export const useRegistrationMailSender = (
	clientId: string,
	operationId: string,
	id: string,
	args: UseQueryArgs<MailSenderRead> = {},
): UseQueryResult<MailSenderRead> => {
	return useQuery<MailSenderRead>({
		queryKey: [...QUERY_KEYS_DOCUMENT.documentMailSenders, "Get", clientId, id, operationId],
		queryFn: ({ signal }) => getRegistrationMailSender({ clientId, operationId, id, signal }),
		...args,
	});
};

export const useRegistrationMailSenderCreate = (
	args: UseMutationArgs<MailSenderCreateRequest, string> = {},
): UseMutationResult<string, Error, MailSenderCreateRequest> => {
	return useMutation<string, Error, MailSenderCreateRequest>({
		mutationFn: (request: MailSenderCreateRequest) => postRegistrationMailSender(request),
		...args,
	});
};

export const useRegistrationMailSenderUpdate = (
	args: UseMutationArgs<MailSenderUpdateRequest> = {},
): UseMutationResult<boolean, Error, MailSenderUpdateRequest> => {
	return useMutation<boolean, Error, MailSenderUpdateRequest>({
		mutationFn: (request: MailSenderUpdateRequest) => putRegistrationMailSender(request),
		...args,
	});
};

export const useRegistrationMailSenderDelete = (
	args: UseMutationArgs<MailSenderDeleteRequest> = {},
): UseMutationResult<boolean, Error, MailSenderDeleteRequest> => {
	return useMutation<boolean, Error, MailSenderDeleteRequest>({
		mutationFn: (request: MailSenderDeleteRequest) => deleteRegistrationMailSender(request),
		...args,
	});
};
