import { useEffect, useRef, useState } from "react";

import ParticipantDetailsBenefitsMatrix from "@application/Components/participants/details/benefits/ParticipantDetailsBenefitsMatrix";
import PrivilegesController from "@application/Controllers/PrivilegesController";
import PrivilegesMatrixFactory from "@application/factory/privileges/PrivilegesMatrixFactory";
import AppBox from "@infrastructure/components/interface/box/AppBox";
import Loading from "@infrastructure/components/interface/loading/Loading";
import K4Matrix from "@infrastructure/components/interface/matrix/K4Matrix";
import { NoData, useContextModule } from "@key4-front-library/core";
import { Box, Stack, Tooltip } from "@mui/material";
import type { GridAlignment, GridColumns, GridRowsProp } from "@mui/x-data-grid";
import { t } from "i18next";
import { useParams } from "react-router-dom";

export const ParticipantDetailsPrivileges = () => {
	const { participantId = "" } = useParams();
	const { client, event } = useContextModule();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [rowsPrivilegesMatrix, setRowsPrivilegesMatrix] = useState<GridRowsProp | undefined>();
	const [columnsPrivilegesMatrix, setColumnsPrivilegesMatrix] = useState<GridColumns | undefined>();

	const defaultStyleColumnsParams = {
		flex: 1,
		disableReorder: true,
		headerAlign: "center" as GridAlignment,
	};

	const pinnedToLeftColumns = useRef([
		{
			...defaultStyleColumnsParams,
			field: "rank",
			headerName: t("old.registration.privileges.rank.label"),
			minWidth: 60,
			renderHeader: (params: any) => {
				return (
					<Box fontWeight={500} className={"menuHiddenFix"}>
						{params.colDef.headerName}
					</Box>
				);
			},
		},
		{
			...defaultStyleColumnsParams,
			field: "Privilege",
			headerName: t("old.registration.privileges.privilege.label"),
			minWidth: 140,
			cellClassName: "column-background-dark",
			renderHeader: (params: any) => {
				return (
					<Box fontWeight={500} className={"menuHiddenFix"}>
						{params.colDef.headerName}
					</Box>
				);
			},
		},
		{
			...defaultStyleColumnsParams,
			field: "costCenter",
			headerName: t("old.registration.privileges.costCenter.label"),
			minWidth: 200,
			cellClassName: "column-background-light",
			renderHeader: (params: any) => {
				return (
					<Tooltip title={params.colDef.headerName}>
						<Box fontWeight={500}>{params.colDef.headerName}</Box>
					</Tooltip>
				);
			},
		},
	]);

	useEffect(() => {
		const fetchPrivileges = async () => {
			const response = await PrivilegesController.readListParticipantPrivileges(client.id, event.id, participantId);
			const { data: privileges } = response;

			const initialRows = PrivilegesMatrixFactory.formatRows(privileges).filter((value) => !value.isPotential);
			const initialColumns = PrivilegesMatrixFactory.formatColumns(privileges[0]?.benefits) ?? [];
			setRowsPrivilegesMatrix(initialRows);
			setColumnsPrivilegesMatrix([...pinnedToLeftColumns.current, ...initialColumns]);
		};
		try {
			setIsLoading(true);
			fetchPrivileges();
			setIsLoading(false);
		} catch (error) {
			console.error(error);
		}
	}, [event, participantId]);

	if (isLoading) {
		return <Loading />;
	}

	if (!rowsPrivilegesMatrix || !columnsPrivilegesMatrix) {
		return <NoData />;
	}

	return (
		<Stack spacing={3}>
			<AppBox>
				<ParticipantDetailsBenefitsMatrix participantId={participantId} />
			</AppBox>
			<AppBox>
				<K4Matrix
					rows={rowsPrivilegesMatrix}
					columns={columnsPrivilegesMatrix}
					setDefaultRowHeight
					noRowsMessage={t("old.registration.privileges.privilege.noData")}
					pinnedColumnsLeft={["rank", "Privilege", "costCenter"]}
				/>
			</AppBox>
		</Stack>
	);
};
