import { t } from "i18next";
import { useRef, useState } from "react";

import { ApplicationColors } from "@application/Configurations/color.configuration";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import useClickOutside from "@infrastructure/services/hooks/useClickOutside";
import { useIdentity } from "@key4-front-library/core";
import { ListItem } from "@key4-front-library/core/Bo/Components/ListItem";
import type { DtoCostCenterGet } from "@key4-front-library/core/Dto";
import { List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, useTheme } from "@mui/material";

interface Props {
	costCenters: Array<DtoCostCenterGet>;
	handleOpenModalForm: (isNew: boolean, costCenter?: DtoCostCenterGet) => void;
	handleDelete: (id: string) => void;
}
const CostCenterList = (props: Props) => {
	const { costCenters, handleDelete, handleOpenModalForm } = props;

	const ref = useRef(null);
	const theme = useTheme();
	const identity = useIdentity();

	const translations = {
		buttons: {
			delete: t("old.form.buttons.delete"),
		},
	};

	const [open, setOpen] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined);
	const [currentCostCenter, setCurrentCostCenter] = useState<DtoCostCenterGet | null>(null);

	useClickOutside(ref, () => {
		setOpen(false);
	});

	const handleMenuItemsOpen = (event: React.MouseEvent<HTMLElement>, _costCenter: DtoCostCenterGet) => {
		setCurrentCostCenter(_costCenter);
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};

	const handleMenuItemsClick = () => {
		handleDelete(currentCostCenter?.id!);
	};

	const renderCostCenter = (_costCenter: DtoCostCenterGet) => {
		return (
			<ListItem
				key={_costCenter.id}
				onClick={(event: React.MouseEvent<HTMLElement>) => {
					handleMenuItemsOpen(event, _costCenter);
				}}
			>
				<ListItemButton
					onClick={() => {
						handleOpenModalForm(false, _costCenter);
					}}
					key={`list-item-button-${_costCenter.id}`}
					disableRipple
					disableGutters
					sx={{
						":hover": {
							bgcolor: ApplicationColors.transparent.main,
						},
					}}
				>
					<ListItemText
						primary={_costCenter.name}
						sx={{
							color: identity.darkmode ? ApplicationColors.white.main : ApplicationColors.blue.light,
							fontSize: "21px",
						}}
					/>
				</ListItemButton>
			</ListItem>
		);
	};

	return (
		<>
			<List
				sx={{
					"& li.MuiListItem-root": {
						marginBottom: "15px",
						"&:last-child": { marginBottom: "0px" },
					},
				}}
			>
				{costCenters.map(renderCostCenter)}
			</List>
			<Menu open={open} anchorEl={anchorEl}>
				<MenuItem ref={ref} disableRipple>
					<ListItemIcon aria-label="delete" onClick={handleMenuItemsClick}>
						<Icon prefix="far" iconName={"trash-can"} color={theme.palette.secondary.main} size="1x" />
					</ListItemIcon>
					<ListItemText>{translations.buttons.delete}</ListItemText>
				</MenuItem>
			</Menu>
		</>
	);
};

export default CostCenterList;
