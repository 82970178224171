import { t } from "i18next";
import { useEffect, useRef } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";
import { ButtonCancel, ButtonSave } from "@infrastructure/components/interface/uikit/K4Button";
import FormFieldRule from "@infrastructure/services/i18n/i18nFormRulesParser";
import StringService from "@infrastructure/services/string/string.service";
import type { DataCypress } from "@key4-front-library/core";
import FormControlSelect from "@key4-front-library/core/Bo/Components/FormControl/FormControlSelect";
import type { DtoBenefit, DtoBenefitGet } from "@key4-front-library/core/Dto";
import { EnumBenefitCalculationType, EnumBenefitValueType } from "@key4-front-library/core/Enums";
import { DialogActions, Stack, TextField } from "@mui/material";

interface benefitTypesDisplay {
	value: EnumBenefitValueType;
	label: string;
	id: string;
}

interface benefitCalculationsDisplay {
	value: EnumBenefitCalculationType;
	label: string;
	id: string;
}

type Props = DataCypress & {
	isNew: boolean;
	benefit?: DtoBenefit | DtoBenefitGet;
	handleSaveBenefit: (isNew: boolean, benefit: DtoBenefitGet) => void;
	handleModaleClose: () => void;
};

const BenefitEditionForm = (props: Props) => {
	const { isNew, benefit, handleSaveBenefit, handleModaleClose } = props;

	const isFirstMount = useRef(true);

	const translations = {
		buttons: {
			save: t("old.form.buttons.save"),
			cancel: t("old.form.buttons.cancel"),
		},
		benefits: {
			name: t("old.registration.privileges.benefit.form.name"),
			valueType: {
				label: t("old.registration.privileges.benefit.form.valueType.label"),
				money: t("old.registration.privileges.benefit.form.valueType.money"),
				bit: t("old.registration.privileges.benefit.form.valueType.bit"),
				numeric: t("old.registration.privileges.benefit.form.valueType.numeric"),
				real: t("old.registration.privileges.benefit.form.valueType.real"),
				percent: t("old.registration.privileges.benefit.form.valueType.percent"),
				date: t("old.registration.privileges.benefit.form.valueType.date"),
				string: t("old.registration.privileges.benefit.form.valueType.string"),
			},
			calculation: {
				label: t("old.registration.privileges.benefit.form.calculation.label"),
				highestRank: t("old.registration.privileges.benefit.form.calculation.highestRank"),
				lowestRank: t("old.registration.privileges.benefit.form.calculation.lowestRank"),
				maxValue: t("old.registration.privileges.benefit.form.calculation.maxValue"),
				minValue: t("old.registration.privileges.benefit.form.calculation.minValue"),
				concat: t("old.registration.privileges.benefit.form.calculation.concat"),
			},
			concatSeparator: t("old.registration.privileges.benefit.form.concatSeparator"),
		},
	};

	const Schema: any = yup.object({
		name: yup.string().required(FormFieldRule.Required(translations.benefits.name)),
		concatSeparator: yup.string().required(FormFieldRule.Required(translations.benefits.concatSeparator)),
	});
	const {
		register,
		handleSubmit,
		control,
		setValue,
		formState: { isSubmitting, isDirty, isValid, errors },
	} = useForm<any>({
		resolver: yupResolver(Schema),
		defaultValues: benefit,
		mode: "onChange",
	});
	const benefitTypeWatcher = useWatch({
		control,
		name: "valueType",
	});
	const benefitCalculationTypeWatcher = useWatch({
		control,
		name: "calculationType",
	});

	const benefitTypes: Array<benefitTypesDisplay> = [
		{
			value: EnumBenefitValueType.MONEY,
			label: translations.benefits.valueType.money,
			id: StringService.transformCamelToSnake(EnumBenefitValueType.MONEY),
		},
		{
			value: EnumBenefitValueType.BIT,
			label: translations.benefits.valueType.bit,
			id: StringService.transformCamelToSnake(EnumBenefitValueType.BIT),
		},
		{
			value: EnumBenefitValueType.NUMERIC,
			label: translations.benefits.valueType.numeric,
			id: StringService.transformCamelToSnake(EnumBenefitValueType.NUMERIC),
		},
		{
			value: EnumBenefitValueType.REAL,
			label: translations.benefits.valueType.real,
			id: StringService.transformCamelToSnake(EnumBenefitValueType.REAL),
		},
		{
			value: EnumBenefitValueType.PERCENT,
			label: translations.benefits.valueType.percent,
			id: StringService.transformCamelToSnake(EnumBenefitValueType.PERCENT),
		},
		{
			value: EnumBenefitValueType.DATE,
			label: translations.benefits.valueType.date,
			id: StringService.transformCamelToSnake(EnumBenefitValueType.DATE),
		},
		{
			value: EnumBenefitValueType.STRING,
			label: translations.benefits.valueType.string,
			id: StringService.transformCamelToSnake(EnumBenefitValueType.STRING),
		},
	];
	const commonBenefitCalculation: Array<benefitCalculationsDisplay> = [
		{
			value: EnumBenefitCalculationType.HIGHEST_RANK,
			label: translations.benefits.calculation.highestRank,
			id: StringService.transformCamelToSnake(EnumBenefitCalculationType.HIGHEST_RANK),
		},
		{
			value: EnumBenefitCalculationType.LOWEST_RANK,
			label: translations.benefits.calculation.lowestRank,
			id: StringService.transformCamelToSnake(EnumBenefitCalculationType.LOWEST_RANK),
		},
	];
	const benefitCalculation: Array<benefitCalculationsDisplay> = [
		...commonBenefitCalculation,
		{
			value: EnumBenefitCalculationType.MAX_VALUE,
			label: translations.benefits.calculation.maxValue,
			id: StringService.transformCamelToSnake(EnumBenefitCalculationType.MAX_VALUE),
		},
		{
			value: EnumBenefitCalculationType.MIN_VALUE,
			label: translations.benefits.calculation.minValue,
			id: StringService.transformCamelToSnake(EnumBenefitCalculationType.MIN_VALUE),
		},
	];
	const benefitCalculationForString: Array<benefitCalculationsDisplay> = [
		...commonBenefitCalculation,
		{
			value: EnumBenefitCalculationType.CONCAT,
			label: translations.benefits.calculation.concat,
			id: StringService.transformCamelToSnake(EnumBenefitCalculationType.CONCAT),
		},
	];

	let benefitCalculationArray = benefitCalculation;
	// different options of calculation if the type of value is a string
	if (benefitTypeWatcher === "string") {
		benefitCalculationArray = benefitCalculationForString;
	}

	useEffect(() => {
		// watcher on type of value to disable a select input without a preselected value
		// example: choose type "numeric" and calculation "minValue"
		// next if we choose type "string", calculation input will be empty
		if (isFirstMount.current) {
			isFirstMount.current = false;
			return;
		}
		// setValue from useForm executed on firstMount but we don't want our default values to be changed at this moment
		// setValue should only be executed on benefit type change
		setValue("calculationType", EnumBenefitCalculationType.HIGHEST_RANK);
	}, [benefitTypeWatcher, setValue, isFirstMount]);

	const onSubmit = (data: DtoBenefitGet) => {
		// if concatSeparator input isn't displayed, send an empty string to backend
		if (benefitCalculationTypeWatcher !== EnumBenefitCalculationType.CONCAT) {
			data.concatSeparator = "";
		}
		handleSaveBenefit(isNew, data);
		handleModaleClose();
	};

	return (
		<Stack spacing={3} mt={1}>
			<TextField
				label={translations.benefits.name}
				variant="outlined"
				{...register("name")}
				fullWidth
				error={!!errors.name}
				helperText={<>{errors.name?.message}</>}
			/>
			<Controller
				name={"valueType"}
				control={control}
				render={({ field: { onChange, value } }) => (
					<FormControlSelect
						label={translations.benefits.valueType.label}
						onChange={onChange}
						value={value}
						items={benefitTypes.map((benefit) => {
							return {
								key: benefit.id,
								label: benefit.label,
							};
						})}
					/>
				)}
			/>
			<Controller
				name={"calculationType"}
				control={control}
				render={({ field: { onChange, value } }) => (
					<FormControlSelect
						label={translations.benefits.calculation.label}
						onChange={onChange}
						value={value}
						items={benefitCalculationArray.map((calcul) => {
							return {
								key: calcul.value,
								label: calcul.label,
							};
						})}
					/>
				)}
			/>
			{benefitCalculationTypeWatcher === EnumBenefitCalculationType.CONCAT && (
				<TextField
					label={translations.benefits.concatSeparator}
					variant="outlined"
					{...register("concatSeparator")}
					fullWidth
					error={!!errors.concatSeparator}
					helperText={<>{errors.concatSeparator?.message}</>}
				/>
			)}

			<DialogActions>
				<ButtonCancel label={translations.buttons.cancel} onClick={handleModaleClose} sx={{ mr: 1 }} />

				<ButtonSave label={translations.buttons.save} disabled={!isDirty || !isValid || isSubmitting} onClick={handleSubmit(onSubmit)} />
			</DialogActions>
		</Stack>
	);
};

export default BenefitEditionForm;
