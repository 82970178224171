import { t } from "i18next";

import CustomCellTotalBenefits from "@application/Components/participants/details/benefits/CustomCellTotalBenefits";
import { EBenefitRow } from "@application/Enums/BenefitsRow.enum";
import { ECountry } from "@domain/interfaces/country.interface";
import { ECurrency } from "@domain/interfaces/currency.interface";
import NumberThousandSeparator from "@infrastructure/components/interface/textFormat/NumberThousandSeparator";
import type { IMenuItems } from "@infrastructure/model/interfaces/navigation/menuItems.interface";
import DateService from "@infrastructure/services/dates/date.service";
import type { DtoBenefitGet, DtoBenefitValue, DtoCostCenterGet, DtoPrivilegeList } from "@key4-front-library/core/Dto";
import { EnumBenefitValueType } from "@key4-front-library/core/Enums";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Icon, Stack, Tooltip, Typography } from "@mui/material";
import type { GridAlignment, GridColumnHeaderParams } from "@mui/x-data-grid";

const formatBenefitType = (valueType: EnumBenefitValueType) => {
	switch (valueType) {
		case EnumBenefitValueType.MONEY:
		case EnumBenefitValueType.NUMERIC:
		case EnumBenefitValueType.REAL:
		case EnumBenefitValueType.PERCENT:
			return "number";
		case EnumBenefitValueType.BIT:
			return "boolean";
		default:
			return valueType;
	}
};

const isMoneyType = (valueType: EnumBenefitValueType) => valueType === EnumBenefitValueType.MONEY;

const isPercentType = (valueType: EnumBenefitValueType) => valueType === EnumBenefitValueType.PERCENT;

const isDateType = (valueType: EnumBenefitValueType) => valueType === EnumBenefitValueType.DATE;

const formatBenefitValue = (valueType: EnumBenefitValueType, value: string) => {
	switch (valueType) {
		case EnumBenefitValueType.BIT:
			return value === "True";
		case EnumBenefitValueType.DATE:
			if (value) {
				return DateService.Format.GetDateFormatedFromIso({
					date: value,
				});
			}
			return;
		case EnumBenefitValueType.MONEY:
			if (!value) {
				return "";
			}
			return value;
		default:
			return value;
	}
};

const currencyFormatter = new Intl.NumberFormat(ECountry.frFR, {
	style: "currency",
	currency: ECurrency.EUR,
});

const formatRows = (privilegesTmp: Array<DtoPrivilegeList>): Array<DtoPrivilegeList> => {
	return privilegesTmp.map((privilege: DtoPrivilegeList) => {
		const objectTmp = JSON.parse(JSON.stringify(privilege));
		// remove benefits from of privilege object and keep the rest
		let { ...privilegeObject } = objectTmp;
		privilegeObject.rank = objectTmp.order;
		// change key 'name' to 'Privilege' to match 'Privilege' column
		privilegeObject.Privilege = privilegeObject.name;
		delete privilegeObject.name;
		// format costCenter
		if (privilege.costCenter) {
			privilegeObject.costCenter = privilege.costCenter.name;
		}
		// add each benefit into privilege object
		privilege.benefits.forEach((item) => {
			privilegeObject = {
				...privilegeObject,
				[item.name]: PrivilegesMatrixFactory.formatBenefitValue(item.valueType, item.value),
			};
		});
		return privilegeObject;
	});
};

interface formatParticipantBenefitsRowsProps {
	initialRow: {
		id: string;
		calculatedTotalBenefits: string;
	};
	benefits: Array<DtoBenefitValue>;
}

const formatParticipantBenefitsRows = (props: formatParticipantBenefitsRowsProps) => {
	const { initialRow, benefits } = props;
	// add each benefit into row
	let rowWithBenefits = initialRow;
	for (const benefit of benefits) {
		rowWithBenefits = {
			...rowWithBenefits,
			[benefit.name]: PrivilegesMatrixFactory.formatBenefitValue(benefit.valueType, benefit.value ?? ""),
		};
	}
	return rowWithBenefits;
};

const columnInitialization = (benefit: DtoBenefitValue, isEditable?: boolean) => {
	const needsValueFormatter = PrivilegesMatrixFactory.isMoneyType(benefit.valueType) || PrivilegesMatrixFactory.isPercentType(benefit.valueType);
	return {
		id: benefit.id,
		field: benefit.name,
		description: benefit.name,
		editable: isEditable,
		flex: 1,
		minWidth: 210,
		headerAlign: "center" as GridAlignment,
		type: PrivilegesMatrixFactory.formatBenefitType(benefit.valueType),
		sortable: false,
		...(needsValueFormatter && {
			valueFormatter: ({ value }: any) => {
				if (PrivilegesMatrixFactory.isMoneyType(benefit.valueType)) {
					return PrivilegesMatrixFactory.currencyFormatter.format(value);
				}
				if (PrivilegesMatrixFactory.isPercentType(benefit.valueType)) {
					return value ? `${value} %` : value;
				}
			},
		}),
		disableReorder: true,
	};
};
const formatColumns = (benefits: Array<DtoBenefitValue>, isEditable?: boolean) =>
	benefits.map((benefit) => {
		return columnInitialization(benefit, isEditable);
	}) ?? [];

const formatParticipantBenefitsColumns = (
	benefits: Array<DtoBenefitValue>,
	benefitsDefinition: Array<DtoBenefitGet>,
	costCenters: Array<DtoCostCenterGet>,
	overridedBenefits: Array<DtoBenefitValue> | undefined | null,
) => {
	const findCalculationType = (benefit: DtoBenefitValue) => {
		for (const benefitDefinition of benefitsDefinition) {
			if (benefitDefinition.id === benefit.id) {
				return benefitDefinition.calculationType;
			}
		}
	};
	return benefits.map((benefit) => {
		const customHeaderWithCalculationType = {
			renderHeader: (params: GridColumnHeaderParams) => {
				const calculationType = findCalculationType(benefit);
				return (
					<Tooltip title={`${calculationType} ${params.field}`}>
						<Stack spacing={-3} alignItems={"center"}>
							<Typography color={"text.disabled"} fontSize={"12px"}>
								{calculationType}
							</Typography>
							<Box fontWeight={"500"}>{params.field}</Box>
						</Stack>
					</Tooltip>
				);
			},
		};
		const customCellListOfCostCenters = {
			renderCell(params: any) {
				const overridedBenefit = overridedBenefits?.find((el) => {
					// need to compare the params from datagrid MUI and the overridedBenefits
					// because costCenters[] in params are costCenters from current benefits.
					// Current benefits are used to set columns for current, potential and custom benefits
					return el.id === params.colDef.id;
				});
				const benefitType = benefits.find((el) => {
					return el.id === params.colDef.id;
				});
				// ! use params.colDef instead of benefit. Wrong values ?
				return (
					<>
						{params.row.id !== EBenefitRow.CUSTOM_TOTAL_BENEFITS ? (
							<Stack spacing={"0.5rem"} my={"1rem"} width={"100%"}>
								{benefitType?.valueType === EnumBenefitValueType.MONEY && (
									<Stack alignItems={"flex-end"}>
										<Box>
											<NumberThousandSeparator value={Number(benefit.value ?? "")} />
											&nbsp;€
										</Box>
									</Stack>
								)}
								{benefitType?.valueType === EnumBenefitValueType.NUMERIC && (
									<Stack alignItems={"flex-end"}>
										<NumberThousandSeparator value={Number(benefit.value ?? 0)} />
									</Stack>
								)}
								{benefitType?.valueType === EnumBenefitValueType.REAL && (
									<Stack alignItems={"flex-end"}>
										<NumberThousandSeparator value={Number(benefit.value ?? 0)} />
									</Stack>
								)}
								{benefitType?.valueType === EnumBenefitValueType.DATE && benefit.value && (
									<Box>
										{DateService.Format.ShortDate({
											date: DateService.Convert.GetDateFromIsoString(benefit.value),
										})}
									</Box>
								)}
								{benefitType?.valueType === EnumBenefitValueType.STRING && <Box>{benefit.value}</Box>}
								{benefitType?.valueType === EnumBenefitValueType.PERCENT && benefit.value && (
									<Stack alignItems={"flex-end"}>
										<Box>
											<NumberThousandSeparator value={Number(benefit.value)} />
											&nbsp;%
										</Box>
									</Stack>
								)}
								{benefitType?.valueType === EnumBenefitValueType.BIT && (
									<Stack alignItems={"center"}>
										<Icon>{params.value ? <CheckIcon color="disabled" fontSize="small" /> : <CloseIcon color="disabled" fontSize="small" />}</Icon>
									</Stack>
								)}

								<Stack alignItems={"flex-end"}>
									{benefit.costCenters?.map((costCenter, index) => {
										return (
											<Box key={index} color={"text.disabled"} fontSize={"12px"}>
												{costCenter.name}
											</Box>
										);
									})}
								</Stack>
							</Stack>
						) : (
							<CustomCellTotalBenefits
								benefitId={params.colDef.id}
								costCentersList={costCenters}
								benefitValue={overridedBenefit?.value ?? benefitType?.value ?? ""}
								benefitType={overridedBenefit?.valueType ?? benefitType?.valueType}
								costCenter={overridedBenefit?.costCenters?.[0] ?? null}
								displayCellBorder={!overridedBenefit?.valueType}
							/>
						)}
					</>
				);
			},
		};

		return {
			...columnInitialization(benefit, false),
			costCenters: benefit.costCenters,
			// All columns has a custom header and a custom cell for custom total
			...customHeaderWithCalculationType,
			// For each type of benefit, display its custom cell
			...customCellListOfCostCenters,
		};
	});
};

interface GetMenuItemsTranslationsProps {
	menuItems: IMenuItems;
}

const GetMenuItemsTranslations = (props: GetMenuItemsTranslationsProps): IMenuItems => {
	const { menuItems } = props;
	menuItems.map((menuItem) => {
		menuItem.name = t(`old.registration.privileges.tabs.${menuItem.reference}`) ?? menuItem.name;
		return menuItem;
	});
	return menuItems;
};

const PrivilegesMatrixFactory = {
	formatBenefitType,
	isMoneyType,
	isPercentType,
	isDateType,
	formatBenefitValue,
	currencyFormatter,
	formatRows,
	formatColumns,
	GetMenuItemsTranslations,
	formatParticipantBenefitsColumns,
	formatParticipantBenefitsRows,
};

export default PrivilegesMatrixFactory;
