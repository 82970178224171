import type { CSSProperties } from "react";

import { ApplicationColors } from "@application/Configurations/color.configuration";
import { findIconDefinition, type IconDefinition, type IconLookup, type IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { IconProps } from "@infrastructure/model/@types/icons/icon";
import { alpha } from "@mui/material";
import Box from "@mui/material/Box";

export const Icon = (props: IconProps) => {
	const { prefix, iconName, ...properties } = props;
	const { size, color, spin, border, pull, flip, rotation, isCenterIcon } = properties;

	const prefixValue: IconPrefix = prefix || "far";

	const iconLookup: IconLookup = {
		prefix: prefixValue,
		iconName,
	};

	const iconIconDefinition: IconDefinition = findIconDefinition(iconLookup);

	if (iconIconDefinition === undefined) {
		console.info(`%cIcon not found : { prefix: "${prefix}", iconName: "${iconName}" }`, `color: ${ApplicationColors.orange}`);
		return <></>;
	}

	const iconStyle = {
		"--fa-primary-color": ApplicationColors.green.main,
		"--fa-secondary-color": alpha(ApplicationColors.green.main, 0.7),
	};

	return (
		<Box color={color} data-testid="icon">
			{isCenterIcon ? (
				<svg width="24" height="24" viewBox="0 0 24 20" preserveAspectRatio="xMidYMid meet">
					<FontAwesomeIcon
						icon={iconIconDefinition}
						size={size}
						spin={spin}
						rotation={rotation}
						border={border}
						pull={pull}
						flip={flip}
						style={iconStyle as CSSProperties}
					/>
				</svg>
			) : (
				<Box sx={{ pt: "2px" }}>
					<FontAwesomeIcon
						icon={iconIconDefinition}
						size={size}
						spin={spin}
						rotation={rotation}
						border={border}
						pull={pull}
						flip={flip}
						style={iconStyle as CSSProperties}
					/>
				</Box>
			)}
		</Box>
	);
};
