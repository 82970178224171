import ReactDOM from "react-dom/client";

import { Core, DefaultQueryClient } from "@key4-front-library/core";

import App from "./App";

const rootElement = document.getElementById("root");

if (rootElement) {
	ReactDOM.createRoot(rootElement).render(
		<Core queryClient={DefaultQueryClient} queryDebug={true}>
			<App />
		</Core>,
	);
}
