import type React from "react";
import { useState } from "react";

import { ApplicationColors } from "@application/Configurations/color.configuration";
import HooksApp from "@application/Hooks";
import type { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import ActionButton from "@infrastructure/components/interface/button/ActionButton";
import { Icon } from "@infrastructure/components/interface/icons/Icon";
import type { IAction } from "@infrastructure/model/interfaces/action.interface";
import type { IMenuItems } from "@infrastructure/model/interfaces/menuItems.interface";
import { type DataCypress, getDataCypressID } from "@key4-front-library/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
	alpha,
	Card as MuiCard,
	CardActions,
	CardContent,
	CardHeader,
	Collapse,
	Divider,
	Grid,
	IconButton,
	type IconButtonProps,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	styled,
	type Theme,
	Typography,
	useTheme,
} from "@mui/material";
import type { SxProps } from "@mui/system";

interface ExpandMoreProps extends IconButtonProps {
	expand: boolean;
}
const ExpandMore = styled((props: ExpandMoreProps) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
	marginLeft: "auto",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest,
	}),
}));

type Props = DataCypress & {
	children: JSX.Element;
	title?: string | JSX.Element;
	headDivider?: boolean;
	action?: IAction;
	minWidth?: number;
	divider?: boolean;
	backgroundColor?: string;
	boxShadow?: boolean;
	isBorder?: boolean;
	menuItems?: IMenuItems;
	expansible?: boolean;
	pinIcon?: { prefix: IconPrefix };
	setDataPinnedElementClicked?: any;
	sx?: SxProps<Theme>;
};

const Card = (props: Props) => {
	const {
		children,
		title,
		headDivider,
		action,
		divider,
		backgroundColor,
		boxShadow = true,
		isBorder,
		menuItems,
		expansible,
		pinIcon,
		setDataPinnedElementClicked,
		dataCypressID,
		sx,
	} = props;

	const theme = useTheme();
	const { readMode } = HooksApp.AuthHook.useEntity();
	const isDarkMode = readMode() === "dark";
	const hasHeader = title || menuItems || expansible || pinIcon;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [expanded, setExpanded] = useState(true);

	const open = Boolean(anchorEl);

	const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseMenuItems = () => {
		setAnchorEl(null);
	};
	const handleExpandClick = () => {
		setExpanded(!expanded);
	};
	const handlePinClick = () => {
		const childrenPinData = props.children.props;
		setDataPinnedElementClicked(childrenPinData);
	};

	return (
		<MuiCard
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				width: "100%",
				backgroundColor: backgroundColor || "pageBackground.main",
				boxShadow: boxShadow
					? `0 ${isBorder ? "1px 4px" : "7px 26px"}
                       ${alpha(theme.palette.primary.dark, 0.12)}`
					: "0",
				border: isBorder ? `1px solid ${alpha(ApplicationColors.border.main, isDarkMode ? 0.29 : 0.12)}` : "",

				px: 3.75,
				pb: 3.75,
				pt: hasHeader ? (headDivider ? 2.125 : 3.375) : 3.75,
				minHeight: "150px",
				...sx,
			}}
			data-cy={getDataCypressID(dataCypressID)}
		>
			{hasHeader && (
				<CardHeader
					title={
						<>
							{typeof title === "string" ? (
								<Typography
									sx={{
										fontWeight: "light",
										fontSize: "26px",
									}}
									data-cy={getDataCypressID(dataCypressID, ["title"])}
								>
									{title}
								</Typography>
							) : (
								<>{title}</>
							)}
						</>
					}
					sx={{ maxHeight: 50 }}
					action={
						<>
							{pinIcon && (
								<IconButton
									aria-label="pin"
									data-cy={getDataCypressID(dataCypressID, ["pin"])}
									onClick={handlePinClick}
									sx={{
										width: "1.8em",
										height: "1.8em",
									}}
								>
									<Icon prefix={pinIcon.prefix} iconName="thumbtack" size="xs" />
								</IconButton>
							)}

							{menuItems && (
								<>
									<IconButton
										aria-label="settings"
										aria-haspopup="true"
										aria-expanded={open ? "true" : undefined}
										onClick={handleSettingsClick}
										disabled={children.props.type !== "manual"}
										data-cy={getDataCypressID(dataCypressID, ["settings", "button"])}
									>
										<MoreVertIcon />
									</IconButton>
									<Menu
										id="settings-menu"
										anchorEl={anchorEl}
										open={open}
										onClose={handleCloseMenuItems}
										MenuListProps={{
											"aria-labelledby": "button",
										}}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "left",
										}}
										transformOrigin={{
											vertical: "top",
											horizontal: "right",
										}}
										data-cy={getDataCypressID(dataCypressID, ["settings", "menu"])}
									>
										{menuItems.map((item, i) => {
											return (
												<MenuItem
													key={i}
													data-cy={item.dataCypressID}
													onClick={() => {
														item.handleClick(children.props);
														setAnchorEl(null);
													}}
												>
													<ListItemIcon>
														<Icon prefix="far" iconName={item.icon as IconName} size="1x" color={theme.palette.secondary.main} />
													</ListItemIcon>
													<ListItemText>{item.label}</ListItemText>
												</MenuItem>
											);
										})}
									</Menu>
								</>
							)}
							{expansible && (
								<ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
									<ExpandMoreIcon />
								</ExpandMore>
							)}
						</>
					}
				/>
			)}

			{hasHeader && headDivider && <Divider sx={{ mx: -3.75, mt: 2, mb: 3.75 }} />}

			<CardContent
				sx={{
					flex: "12 1 auto",
					display: "flex",
					alignItems: "center",
					"&:last-child": {
						paddingBottom: 0,
					},
				}}
			>
				<Grid container>
					<Grid item xs={12}>
						<Collapse in={expanded} timeout="auto" unmountOnExit>
							{expanded && children}
						</Collapse>
					</Grid>
				</Grid>
			</CardContent>

			{action && (
				<CardActions
					sx={{
						mt: 1,
						display: "flex",
						flexDirection: "column",
						alignItems: action.align && action.align === "right" ? "flex-end" : "flex-start",
					}}
				>
					{divider && (
						<Divider
							sx={{
								my: 1,
								width: "200%",
								marginLeft: "-50%",
								marginRight: "-50%",
							}}
						/>
					)}
					<ActionButton label={action.label} redirectUrl={action.link} icon={action.icon ? action.icon : "arrow-right"} color={action.color} />
				</CardActions>
			)}
		</MuiCard>
	);
};
export default Card;
